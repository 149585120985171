import Image from "components/Image";
import React, { useEffect, useState } from "react";
import leftArrow from "assets/images/leftArrow.png";
import rightArrow from "assets/images/rightArrow.png";
import Video from "components/Video";
import file from "assets/images/file_icon.png";

function Modal({
  key_,
  media,
  selectedMedia,
  onPreviousClick,
  onNextClick,
  count,
}) {
  // const [selectedMedia, setSelectedMedia] = useState(null);

  // useEffect(() => {
  //   setSelectedMedia(media[count]);
  // }, [media]);

  return (
    <div
      className="modal"
      id={"exampleModal" + key_}
      tabindex="-1"
      role="dialog"
      aria-labelledby={"exampleModalLabel" + key_}
      aria-hidden="true">
      {count > 0 && (
        <div className="position-absolute modal-left-button">
          <button onClick={onPreviousClick}>
            <img src={leftArrow} alt="" />
          </button>
        </div>
      )}
      {count + 1 < media?.length && (
        <div className="position-absolute modal-right-button">
          <button onClick={onNextClick}>
            <img src={rightArrow} alt="" />
          </button>
        </div>
      )}
      <div
        className="modal-dialog modal-dialog-centered modal-lg"
        role="document">
        <div className="modal-content">
          {/* <div className="modal-header">
            <h5 className="modal-title" id={"exampleModalLabel" + key_}>
              Modal title
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div> */}
          <div className="modal-body p-0">
            {selectedMedia?.type?.toLowerCase() === "video" && (
              <div className="mediaVideo">
                <Video
                  id={"dsd" ? "dsd" : ""}
                  src={selectedMedia?.url ? selectedMedia?.url : ""}
                  className=""
                  width="100%"
                  height="100%"
                  controls="controls"
                  // onClick={onClick}
                />
              </div>
            )}
            {selectedMedia?.type?.toLowerCase() === "image" && (
              <div className="">
                <Image
                  className="image-media w-100"
                  src={selectedMedia?.url ? selectedMedia?.url : ""}
                />
              </div>
            )}
            {selectedMedia?.type?.toLowerCase() === "file" && (
              <div className="text-center p-5">
                <a target={"_blank"} href={selectedMedia?.url}>
                  <Image
                    className="border-20 image-media file-icon cursor-pointer"
                    src={file}
                  />
                </a>
              </div>
            )}
          </div>
          {/* <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal">
              Close
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Modal;
