import React from "react";

const Footer = (props) => {
  const { page } = props;
  return (
    <>
      <div className="pageFooter flexRow">
        <div className="numberBox">{page}</div>
        <div className="whiteLine"></div>
        <p className="leftTitle bold text-light">routes-app.com</p>
      </div>
    </>
  );
};

export default Footer;
