import Image from "components/Image";
import React from "react";

const Card = ({ clubData }) => {
  return (
    <div className="col-md-4 p-0 d-flex flex-column justify-content-center flex-unset gap-35 align-items-center">
      <Image
        className="previous-club-badge"
        src={clubData?.image ? clubData?.image : ""}
      />
      <h5 className="green font-weight-bold">
        {clubData?.name ? clubData?.name : ""}
      </h5>
      <div className="age-range d-flex flex-row align-items-center justify-content-between w-100 gap-25">
        <p className="year year-1">
          {clubData?.startDate ? clubData?.startDate : ""}
        </p>
        <div className="year-range-separator"></div>
        <p className="year year-2">
          {clubData?.endDate ? clubData?.endDate : ""}
        </p>
      </div>
    </div>
  );
};

export default Card;
