import React from "react";

const Video = (props) => {
  const {
    id = "",
    src = "",
    className = "",
    style,
    width,
    height,
    onClick,
    controls,
    poster,
  } = props;

  return (
    <video
      id={id}
      // src={src}
      className={className}
      style={style}
      width={width}
      height={height}
      onClick={onClick}
      controls={controls}
      poster={poster}>
      <source src={src} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
};

export default Video;
