export const stats_key = [
  {
    id: "lightGrey",
    name: "Season",
  },
  {
    id: "darkGrey",
    name: "Career",
  },
];
