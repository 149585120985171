import React, { useEffect } from "react";
import Heading from "components/pages/Sports/TraditionalCv/components/Heading/Heading";
import "assets/js/traditional/barChart";
import { transferableSkillsBarChart } from "assets/js/traditional/barChart";
import { useSelector } from "react-redux";

const TransferableSkills = () => {
  let { user } = useSelector((state) => state.user);
  useEffect(() => {
    transferableSkillsBarChart();
  }, [user]);

  // const TransferableSkillsData = [
  //   {
  //     title: "Excellence",
  //     data_percentage: "80",
  //     description:
  //       "Danny has no problems with confidence and is a key influencer in the team.",
  //   },
  //   {
  //     title: "Confidence",
  //     data_percentage: "80",
  //     description:
  //       "Danny has no problems with confidence and is a key influencer in the team.",
  //   },
  //   {
  //     title: "Reliability",
  //     data_percentage: "90",
  //     description:
  //       "Danny has no problems with confidence and is a key influencer in the team.",
  //   },
  //   {
  //     title: "Adaptability",
  //     data_percentage: "60",
  //     description:
  //       "Danny has no problems with confidence and is a key influencer in the team.",
  //   },
  //   {
  //     title: "Determination",
  //     data_percentage: "70",
  //     description:
  //       "Danny has no problems with confidence and is a key influencer in the team.",
  //   },
  //   {
  //     title: "Integrity",
  //     data_percentage: "90",
  //     description:
  //       "Danny has no problems with confidence and is a key influencer in the team.",
  //   },
  // ];
  console.log("user", user);
  return (
    <>
      <Heading name="Transferable Skills" />
      <div className="colGrid3 marginBottom">
        {user?.transferableSkills?.length > 0 &&
          user?.transferableSkills?.map((data) => (
            <div>
              <h6 className="green bold marginBottomSmall">
                {data?.name ? data?.name : ""}
              </h6>
              <div className="barChartOuter marginBottomSmall">
                <div
                  id="Excellence"
                  className="barChartInner"
                  data-percentage={data?.point ? data?.point : ""}></div>
              </div>
              <p>{data?.comment ? data?.comment : ""}</p>
            </div>
          ))}
      </div>
    </>
  );
};

export default TransferableSkills;
