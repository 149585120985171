import React from "react";
import Image from "components/Image";
import Attachments from "components/Education/ExpandInfo/Attachments/Attachments";
import placeHolderImgCourse from "assets/images/placeHolderImgCourse.png";

const ExpandInfo = ({ eductionData, indexData }) => {
  return (
    <div id={`education-expand-tile-row`} className={`w-100 position-relative`}>
      {eductionData?.map((data, i) => (
        <div
          // id="card1-expanded-tile"
          id={`card${indexData + i + 1}-expanded-tile`}
          className="w-100 education-expand-tile position-relative hidden"
        >
          <div className="row w-100 d-flex flex-row gap-50 flex-nowrap unit-attachment-row">
            <div
              // id="card1-units"
              id={`card${indexData + i + 1}-units`}
              className="col-md-4 flex-unset d-flex flex-column p-0 justify-content-start unit-list gap-25"
            >
              {data.modulesData?.map((module_info, index) => (
                <div
                  // id="course-1-unit-1"
                  id={`course-${indexData + i + 1}-unit-${index + 1}`}
                  className="w-100 education-unit d-flex flex-row gap-10 flex-nowrap justify-content-start cursor-pointer"
                >
                  <div className="col-md-2 d-flex flex-column align-items-start p-0 justify-content-center unit-icon-div">
                    <Image
                      src={
                        module_info?.icon
                          ? module_info?.icon
                          : placeHolderImgCourse
                      }
                      className={"unit-icon predicted-icon"}
                    />
                  </div>
                  <div className="col-md-10 d-flex flex-column align-items-start p-0 justify-content-center unit-title-div">
                    <p className="unit-title">{module_info?.name}</p>
                  </div>
                </div>
              ))}
            </div>

            <Attachments attachmentsData={data} index={indexData + i + 1} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default ExpandInfo;
