import ReferencesCard from "components/ReferencesCard";
import React from "react";
import { useSelector } from "react-redux";

const Agent = () => {
  let { user } = useSelector((state) => state.user);

  return (
    <div id="agent" className="flex-fill d-flex flex-row gut-100 section">
      <div className="col d-flex flex-column align-items-start justify-content-center gap-50">
        <h2 id="agent-title" className="text-right">
          My <span className="font-weight-bold">Agent</span>
        </h2>
        <div className="d-flex flex-row gap-50 p-0 w-100 agent-inner">
          <div
            id="agent-1"
            className="col-6 d-flex flex-column gap-25 p-0 flex-unset agent-row"
          >
            {user?.agent?.length > 0 &&
              user?.agent?.map((item) => (
                <ReferencesCard
                  tutorDetails={item}
                  className={"p-0"}
                  sectionName={"agent"}
                />
              ))}
          </div>
          {/* <div
            id="agent-empty"
            className="col-6 d-flex flex-column gap-25 p-0 flex-unset"
          ></div> */}
        </div>
      </div>
    </div>
  );
};

export default Agent;
