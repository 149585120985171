import React from "react";

const DataTable = ({ gamesProgramme }) => {
  return (
    <div className="row item align-items-end p-0 pt-15 gap-50 flex-nowrap w-100">
      <div className="col-8 item-label p-0 flex-unset">
        <p>{gamesProgramme?.title}</p>
      </div>
      {/* <div className="col-2 item-details p-0 flex-unset">
        <div className="row">
          <div className="col-6 d-flex justify-content-end p-0">
            <div className="column-value">
              <p className="season-text">
                {gamesProgramme?.seasonToDate?.appearances}
              </p>
              <p></p>
            </div>
          </div>
          <div className="col-6 d-flex justify-content-end p-0">
            <div className="column-value">
              <p className="career-text">
                {gamesProgramme?.career?.appearances}
              </p>
            </div>
          </div>
        </div>
      </div> */}
      <div className="col-2 item-details p-0 flex-unset">
        <div className="row p-0">
          <div className="col-6 d-flex justify-content-end p-0">
            <div className="column-value">
              <p className="season-text">
                {/* {gamesProgramme?.seasonToDate?.minutes} */}
                {gamesProgramme?.seasonToDate}
              </p>
            </div>
          </div>
          <div className="col-6 d-flex justify-content-end p-0">
            <div className="column-value">
              {/* <p className="career-text">{gamesProgramme?.career?.minutes}</p> */}
              <p className="career-text">{gamesProgramme?.career}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataTable;
