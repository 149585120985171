import VideoCard from "components/VideoCard";
import {
  HighlightGallery,
  videoCard,
} from "components/VideoCard/VideoCard.Script";
import { useSelector } from "react-redux";

const TopLevelVideo = () => {
  let { user } = useSelector((state) => state.user);

  // useEffect(() => {
  //   highlightVideo();
  //   // onHighlightGallery();
  // }, []);

  return (
    <div
      // id="highlights-gallery"
      id="video-gallery"
      className="flex-fill d-flex flex-column gut-100 justify-content-center align-items-center pt-20 section gap-50"
    >
      <div className="col-12 p-0">
        <h2 id="highlights-gallery-title" className="text-left">
          My <span className="font-weight-bold">Video Gallery</span>
        </h2>
      </div>

      <div
        id="main-gallery-item"
        className="col col-12 d-flex flex-column gap-50 flex-nowrap"
      >
        {user?.topLevelVideos?.length > 0 && user?.topLevelVideos[0] && (
          <>
            <div className="col col-12">
              <VideoCard
                videoSrc={
                  user?.topLevelVideos[0]?.url
                    ? user?.topLevelVideos[0]?.url + "#t=0.001"
                    : ""
                }
                id_="main-highlight-video"
                subId="main-gallery-item .bio-video-items"
                onClick={() => {
                  videoCard(
                    "main-highlight-video",
                    "main-gallery-item .bio-video-items"
                  );
                }}
                name={"Highlight"}
              />
            </div>
            <div className="col col-4 main-video-info">
              <h5 className="green font-weight-bold">
                {user?.topLevelVideos[0]?.title}
              </h5>
              <p>{user?.topLevelVideos[0]?.description}</p>
            </div>
          </>
        )}
      </div>

      {/* <div className="p-0 w-100 d-flex flex-row gap-50 flex-nowrap video-row">
        {user?.topLevelVideos?.slice(1)?.map((videoData) => (
          <div className="col col-md-6 highlight-video d-flex flex-column gap-25 d-flex stretch flex-unset">
            <VideoCard
              videoSrc={videoData?.url ? videoData?.url + "#t=0.001" : ""}
              onClick={() => {
                HighlightGallery(
                  ".highlight-video video",
                  ".highlight-video .bio-video-items"
                );
              }}
              name={"Highlight"}
            />
            <div className="d-flex w-100 flex-column video-highlight-info">
              <h5 className="green font-weight-bold">{videoData?.title}</h5>
              <p>{videoData?.description}</p>
            </div>
          </div>
        ))}
      </div> */}

      <div className="p-0 w-100 d-flex flex-row flex-wrap">
        {user?.topLevelVideos?.slice(1)?.map((videoData, index) => (
          <div key={index} className="col-12 col-md-3 mb-4 highlight-video">
            <VideoCard
              videoSrc={videoData?.url ? videoData?.url + "#t=0.001" : ""}
              onClick={() => {
                HighlightGallery(
                  ".highlight-video video",
                  ".highlight-video .bio-video-items"
                );
              }}
              name={"Highlight"}
            />
            <div className="d-flex flex-column video-highlight-info mt-2">
              <h5 className="green font-weight-bold">{videoData?.title}</h5>
              <p>{videoData?.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TopLevelVideo;
