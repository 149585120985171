import Video from "components/Video/Video";

const VideoCard = (props) => {
  const { videoSrc, id_, subId, onClick } = props;
  // useEffect(() => {
  //   // videoCard();
  //   // bioVideo();
  //   HighlightGallery();
  // }, []);

  return (
    <div className="video-wrapper">
      <div
        // id="bio-video-items"
        id={subId}
        className="position-absolute bio-video-items d-flex flex-column justify-content-center align-items-center gap-10"
      >
        {/* <Image src={video_play} className="play-button" />
        <p className="white bio-video-text">
          Play <span className="font-weight-bold">{name} Video</span>
        </p> */}
      </div>

      <Video
        id={id_ ? id_ : ""}
        src={videoSrc}
        className="border-20"
        width="100%"
        height="100%"
        controls="controls"
        onClick={onClick}
      />
    </div>
  );
};

export default VideoCard;
