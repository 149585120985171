// import "./PersonalDevelopment.Script";

import expand_collapse_arrow from "assets/images/expand-collapse-arrow.svg";
import Image from "components/Image";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import $ from "jquery";
import Card from "./Card";

const MiscAttributes = (props) => {
  let { user } = useSelector((state) => state.user);
  const { Category } = props;

  return (
    <>
      <div id="miscAttributes" style={{ width: "100%" }}>
        {user?.customFields
          ?.filter((data) => data.category === Category)
          ?.map((data, i) => (
            <>
              {data?.value !== " " && (
                <div style={{ marginBottom: 50 }}>
                  <p
                    style={{
                      paddingBottom: "10px",
                      marginBottom: "40px",
                      borderBottom: "1px solid #006b00",
                    }}
                  >
                    {data.title.toUpperCase()}
                  </p>
                  <p>
                    {" "}
                    {/* {Array.isArray(data.value) && data.value.map(a => <>{a.title}</>)} */}
                    {!Array.isArray(data.value) && <div>{data.value}</div>}
                  </p>
                </div>
              )}
            </>
          ))}
      </div>
    </>
  );
};

export default MiscAttributes;
