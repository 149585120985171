export const actions = {
  USER_AUTH_REQUEST: "USER_AUTH_REQUEST",
  USER_AUTH_SUCCESS: "USER_AUTH_SUCCESS",

  USER_TOKEN_REQUEST: "USER_TOKEN_REQUEST",
  USER_TOKEN_SUCCESS: "USER_TOKEN_SUCCESS",

  GET_USER_REQUEST: "GET_USER_REQUEST",
  GET_USER_SUCCESS: "GET_USER_SUCCESS ",

  CHECK_VALID_EXPIRY_DATE_REQUEST: "CHECK_VALID_EXPIRY_DATE_REQUEST",
  CHECK_VALID_EXPIRY_DATE_SUCCESS: "CHECK_VALID_EXPIRY_DATE_SUCCESS ",

  CHECK_PASSWORD_REQUEST: "CHECK_PASSWORD_REQUEST",
  CHECK_PASSWORD_SUCCESS: "CHECK_PASSWORD_SUCCESS ",

  SUBMIT_PASSWORD_REQUEST: "SUBMIT_PASSWORD_REQUEST",
  SUBMIT_PASSWORD_SUCCESS: "SUBMIT_PASSWORD_SUCCESS ",

  CATCH_ERROR: "CATCH_ERROR",

  getUser: (data, cvType) => {
    return {
      type: actions.GET_USER_REQUEST,
      data,
      cvType,
    };
  },

  getValidateExpiryDate: (data) => {
    return {
      type: actions.CHECK_VALID_EXPIRY_DATE_REQUEST,
      data,
    };
  },

  checkIsPassword: (data) => {
    return {
      type: actions.CHECK_PASSWORD_REQUEST,
      data,
    };
  },

  submitPassword: (data) => {
    return {
      type: actions.SUBMIT_PASSWORD_REQUEST,
      data,
    };
  },
};
