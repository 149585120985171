import module_1 from "assets/images/module_1.svg";
import module_2 from "assets/images/module_2.svg";

export const moduleImg = [
  {
    image: module_1,
  },
  {
    image: module_2,
  },
];
