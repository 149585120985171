import React from "react";
import { useSelector } from "react-redux";
import Heading from "components/pages/Sports/TraditionalCv/components/Heading/Heading";

const Interests = () => {
  let { user } = useSelector((state) => state.user);

  return (
    <>
      <Heading name="Interests" />
      <div className="colGrid3 marginBottom">
        {user?.interests?.length > 0 &&
          user?.interests?.map((data) => (
            <>
              <div>
                <h6 className="green bold marginBottomSmall">
                  {data?.title ? data?.title : ""}
                </h6>
                <p>{data?.description ? data?.description : ""}</p>
              </div>
            </>
          ))}
      </div>
    </>
  );
};

export default Interests;
