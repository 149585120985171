// eslint-disable-next-line
import $ from "jquery";
/* bio video */
export const bioVideo = (id, subId) => {
  if (id !== undefined && subId !== undefined) {
    var mainVideo = document.getElementById(id);
    var mainBioVideoText = document.querySelector(`#${subId}`);
    var mainIsFirstClick = true;

    mainBioVideoText.addEventListener("click", function () {
      mainVideo.play();
      mainBioVideoText.classList.add("hidden");
      mainIsFirstClick = false;
      // console.log("click bio");
    });

    mainVideo.addEventListener("click", function (event) {
      if (!mainIsFirstClick) {
        if (mainVideo.paused) {
          mainVideo.play();
        } else {
          mainVideo.pause();
        }
        mainBioVideoText.classList.toggle("hidden");
        event.stopPropagation();
      }
    });

    mainVideo.addEventListener("play", function () {
      mainBioVideoText.classList.add("hidden");
    });

    mainVideo.addEventListener("pause", function () {
      if (!mainVideo.ended) {
        mainBioVideoText.classList.remove("hidden");
      }
    });

    mainVideo.addEventListener("ended", function () {
      mainBioVideoText.classList.remove("hidden");
    });
  }
};

/* main highlight video */
export const videoCard = (id, subId) => {
  // var mainHighlight = document.getElementById("main-highlight-video");
  var mainHighlight = document.getElementById(id);
  // var mainTextOverlay = document.querySelector(
  //   "#main-gallery-item .bio-video-items"
  // );

  var mainTextOverlay = document.querySelector(`#${subId}`);

  var mainHighlightFirstClick = true;

  mainTextOverlay.addEventListener("click", function () {
    mainHighlight.play();
    mainTextOverlay.classList.add("hidden");
    mainHighlightFirstClick = false;
  });

  mainHighlight.addEventListener("click", function (event) {
    if (!mainHighlightFirstClick) {
      if (mainHighlight.paused) {
        mainHighlight.play();
      } else {
        mainHighlight.pause();
      }
      mainTextOverlay.classList.toggle("hidden");
      event.stopPropagation();
    }
  });

  mainHighlight.addEventListener("play", function () {
    mainTextOverlay.classList.add("hidden");
  });

  mainHighlight.addEventListener("pause", function () {
    if (!mainHighlight.ended) {
      mainTextOverlay.classList.remove("hidden");
    }
  });

  mainHighlight.addEventListener("ended", function () {
    mainTextOverlay.classList.remove("hidden");
  });
};

/* highlight gallery */

export const HighlightGallery = (id, subId) => {
  var highlightVideos = document.querySelectorAll(id);
  var highlightTextOverlays = document.querySelectorAll(subId);
  // var highlightVideos = document.querySelectorAll("highlight-video video");
  // var highlightTextOverlays = document.querySelectorAll(
  //   ".highlight-video .bio-video-items"
  // );

  var highlightIsFirstClick = [];

  highlightVideos.forEach(function (video, index) {
    var textOverlay = highlightTextOverlays[index];
    highlightIsFirstClick[index] = true;

    textOverlay.addEventListener("click", function () {
      if (video.paused) {
        video.play();
        textOverlay.classList.add("hidden");
      }
    });

    video.addEventListener("click", function (event) {
      if (!highlightIsFirstClick[index]) {
        if (video.paused) {
          video.play();
        } else {
          video.pause();
        }
        textOverlay.classList.toggle("hidden");
        event.stopPropagation();
      }
    });

    video.addEventListener("play", function () {
      var parentHighlightVideo = video.closest(".highlight-video");
      var bioVideoItems =
        parentHighlightVideo.querySelector(".bio-video-items");
      bioVideoItems.classList.add("hidden");
    });

    video.addEventListener("pause", function () {
      if (!video.ended) {
        var parentHighlightVideo = video.closest(".highlight-video");
        var bioVideoItems =
          parentHighlightVideo.querySelector(".bio-video-items");
        bioVideoItems.classList.remove("hidden");
      }
    });

    video.addEventListener("ended", function () {
      var parentHighlightVideo = video.closest(".highlight-video");
      var bioVideoItems =
        parentHighlightVideo.querySelector(".bio-video-items");
      bioVideoItems.classList.remove("hidden");
    });

    // After the initial click, set highlightIsFirstClick to false
    video.addEventListener("playing", function () {
      highlightIsFirstClick[index] = false;
    });
  });
};
