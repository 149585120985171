function setBarWidth(el) {
  let width = el.dataset.percentage;
  el.style.width = width * 10 + "%";
}

export const transferableSkillsBarChart = () => {
  let barCharts = Array.from(document.getElementsByClassName("barChartInner"));
  if (barCharts) {
    for (let i = 0; i < barCharts.length; i++) {
      setBarWidth(barCharts[i]);
    }
  }
};
