import Image from "components/Image";
import React from "react";
import logo from "assets/images/logo512.png";
import "./Loader.css";

const Loader = () => {
  return (
    <div className="ring">
      <Image className="logoImg" src={logo} />
    </div>
  );
};

export default Loader;
