// import "./PersonalDevelopment.Script";

import expand_collapse_arrow from "assets/images/expand-collapse-arrow.svg";
import Image from "components/Image";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import $ from "jquery";
import Card from "./Card";
import Icons from "assets/icons/Icons";

const ExistingQualifications = () => {
  let { user } = useSelector((state) => state.user);

  // useEffect(() => {
  //   personalDevelopmentToggle();
  // }, [user]);

  const PersonalDevelopmentData = [
    {
      title: "KEY STAGE 4 / GCSE QUALIFICATIONS (Compulsory)",
      dataArray: user?.key_stage_4_qualification_compulsory,
    },
    {
      title: "KEY STAGE 4 / GCSE QUALIFICATIONS (Optional)",
      dataArray: user?.key_stage_4_qualification_optional,
    },
    {
      title: "OTHER ACADEMIC QUALIFICATIONS",
      dataArray: user?.Other_academic_qualification,
    },
  ];

  useEffect(() => {
    $(document).ready(function () {
      // console.log($(".list-group-item-qualifications").get());
      $(".list-group-item-qualifications").click(function (event) {
        event.preventDefault(); // Prevent the default jump-to behavior of anchor links
        // console.log("this", this);
        if ($(this).hasClass("active")) {
          $(".personal-development-toggle-text-qualifications").removeClass(
            "active"
          );
          $(".pd-arrow-qualifications").addClass("rotate");
          $(this).removeClass("active");
          // Hide other sections with fade animation
          // eslint-disable-next-line
          $(".section-collapse-qualifications.show")
            .not(target)
            .removeClass("show");
        } else {
          $(".list-group-item-qualifications").removeClass("active");
          $(this).addClass("active");
          var target = $(this).attr("href");
          // eslint-disable-next-line
          var $targetSection = $(target);
          // console.log("$targetSection", $targetSection);
          // Hide other sections with fade animation
          $(".section-collapse-qualifications.show")
            .not(target)
            .removeClass("show");
          $(target).addClass("show");
          // Toggle the clicked section with fade animation
          // $targetSection.collapse("toggle");

          // Remove 'active' class from all 'active-line' and 'personal-development-toggle-text-qualifications' elements
          $(".personal-development-toggle-text-qualifications").removeClass(
            "active"
          );
          $(".pd-arrow-qualifications").addClass("rotate");
          // Add 'active' class to the clicked 'active-line' and 'personal-development-toggle-text-qualifications' elements
          $(this)
            .find(".personal-development-toggle-text-qualifications")
            .addClass("active");
          $(this).find(".pd-arrow-qualifications").removeClass("rotate");
        }
      });
    });
  }, [user]);

  return (
    <>
      <div className="section w-100">
        <div className="col d-flex flex-column align-items-start justify-content-center content-box">
          <h3 className="font-weight-bold green">Existing Qualifications</h3>
          {/* <h2 id="personal-development-title">
            My <span className="font-weight-bold">Personal Development</span>
          </h2> */}
          <div className="col-12 col p-0">
            <div className="row">
              <div className="col-12 col-md-12 p-0">
                <div
                  id="personal-development-toggles"
                  className="list-group d-flex flex-column w-100">
                  {PersonalDevelopmentData?.map((data, i) => (
                    <>
                      {data?.dataArray?.length > 0 && (
                        <div id={`pd-${i + 1}-toggle-section`}>
                          <a
                            key={i}
                            href={`#qualification-item${i + 1}`}
                            className={`list-group-item-qualifications list-group-item-qualifications-action w-100 ${
                              i === 0 ? "active" : "active"
                            } pl-0`}
                            style={{ textDecoration: "none" }}>
                            <div className="d-flex flex-row nav-item-outer align-items-center justify-content-between w-100">
                              <p
                                className={`personal-development-toggle-text-qualifications  ${
                                  i === 0 ? "active" : "active"
                                }`}>
                                {data?.title}
                                {/* {user?.} */}
                              </p>
                              {/* <Image
                                className="pd-arrow-qualifications"
                                src={expand_collapse_arrow}
                              /> */}
                              <Icons
                                name="expand-collapse-arrow"
                                className="pd-arrow-qualifications"
                                fill={user?.brandTheme?.themeColors?.primary}
                              />
                            </div>
                          </a>
                          <div
                            id={`qualification-item${i + 1}`}
                            className={`collapse section-collapse-qualifications  ${
                              i === 0 ? "show" : "show"
                            }`}>
                            <div className="row d-flex flex-row align-items-center gap-50 skills-row w-100">
                              {data?.dataArray?.map((details) => (
                                <Card data={details} />
                              ))}
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* {user?.organization === "football" && (
        <div
          id="tutor-comment"
          className="d-flex justify-content-center align-items-center section"
        >
          {user?.footballAttribute === true && (
            <div className="col-6 col comment-main">
              <h5 className="text-center light-grey">
                {user?.football_bio ? user?.football_bio : ""}
              </h5>
            </div>
          )}
        </div>
      )} */}
    </>
  );
};

export default ExistingQualifications;
