import React from "react";
import Heading from "components/pages/Sports/TraditionalCv/components/Heading/Heading";
import { useSelector } from "react-redux";

const Achievements = () => {
  let { user } = useSelector((state) => state.user);
  return (
    <>
      <Heading name="Achievements" />
      <div className="colGrid3 marginBottom">
        {user?.presentation_awards?.length > 0 &&
          user?.presentation_awards?.map((data) => (
            <div>
              <h6 className="green bold marginBottomSmall">
                {data?.title ? data?.title : ""}
              </h6>
              <p>{data?.description ? data?.description : ""}</p>
            </div>
          ))}
      </div>
    </>
  );
};

export default Achievements;
