import Image from "components/Image";
import Modal from "components/Modal";
import Video from "components/Video";
import VideoCard from "components/VideoCard";
import React, { useCallback, useEffect, useState } from "react";
import file from "assets/images/file_icon.png";
import expand_collapse_arrow from "assets/images/expand-collapse-arrow.svg";
import Icons from "assets/icons/Icons";
import { useSelector } from "react-redux";

const Card = ({ PersonalDevelopmentDetails, key_ }) => {
  // PersonalDevelopmentDetails.media = [
  //   {
  //     id: "14d31d8a-0d41-ebb2-d0be-4de207ac55kc",
  //     type: "image",
  //     url: "https://routeresourcegroup.blob.core.windows.net/image/17168712091839243379C-B364-457F-9096-CAF08A963ED0.png",
  //   },
  //   {
  //     id: "14d31d8a-0d41-ebb2-d0be-4de207ac55bc",
  //     type: "image",
  //     url: "https://routeresourcegroup.blob.core.windows.net/image/17168712091839243379C-B364-457F-9096-CAF08A963ED0.png",
  //   },
  //   {
  //     id: "14d31d8a-0d41-ebb2-d0be-4de207ac55dc",
  //     type: "video",
  //     url: "https://routeresourcegroup.blob.core.windows.net/video/17078079115043B5BE552-90E1-434C-AA29-A0CAD8074356.mp4#t=0.001",
  //   },
  //   {
  //     id: "14d31d8a-0d41-ebb2-d0be-4de207ac59lc",
  //     type: "file",
  //     url: "https://routeresourcegroup.blob.core.windows.net/video/17078079115043B5BE552-90E1-434C-AA29-A0CAD8074356.mp4#t=0.001",
  //   },
  //   {
  //     id: "14d31d8a-0d41-ebb2-d0be-4de207ac59lc",
  //     type: "file",
  //     url: "https://routeresourcegroup.blob.core.windows.net/video/17078079115043B5BE552-90E1-434C-AA29-A0CAD8074356.mp4#t=0.001",
  //   },
  // ];
  const { user } = useSelector((state) => state.user);
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [isExpand, setIsExpand] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [count, setCount] = useState(0);
  const onPreviousClick = useCallback(() => {
    setCount(count === 0 ? 0 : count - 1);
    setSelectedMedia(
      count - 1 > 0
        ? PersonalDevelopmentDetails?.media[count - 1]
        : PersonalDevelopmentDetails?.media[0]
    );
  });
  const onNextClick = useCallback(() => {
    setCount(count + 1);
    setSelectedMedia(
      count + 1 < PersonalDevelopmentDetails?.media?.length
        ? PersonalDevelopmentDetails?.media[count + 1]
        : PersonalDevelopmentDetails?.media[
            PersonalDevelopmentDetails?.media?.length - 1
          ]
    );
  });

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (isModalOpen) {
        if (event.key === "ArrowLeft") {
          onPreviousClick();
        } else if (event.key === "ArrowRight") {
          onNextClick();
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isModalOpen, onPreviousClick, onNextClick]);

  return (
    <div
      className={`personal-development-card ${
        !PersonalDevelopmentDetails?.media?.length ? "" : "d-flex"
      } w-100`}
      key={key_}>
      <div
        className={`personal-development-card-info d-flex flex-column ${
          !PersonalDevelopmentDetails?.media?.length ? "w-100" : ""
        }`}>
        <h5 className="green font-weight-bold">
          {PersonalDevelopmentDetails?.title}
        </h5>
        {PersonalDevelopmentDetails?.startDate && (
          <p>
            {PersonalDevelopmentDetails?.startDate} to{" "}
            {PersonalDevelopmentDetails?.endDate}
          </p>
        )}
        <p>{PersonalDevelopmentDetails?.description}</p>
      </div>
      <div className="personal-media">
        <div className="mediaCards d-flex flex-wrap">
          {PersonalDevelopmentDetails?.media &&
            PersonalDevelopmentDetails?.media
              ?.slice(
                0,
                !isExpand ? 4 : PersonalDevelopmentDetails?.media?.length
              )
              ?.map((media, i) => {
                return (
                  <div
                    className="mediaCard"
                    key={media?.id}
                    onClick={() => {
                      setSelectedMedia(media);
                      setCount(i);
                      setIsModalOpen(true);
                    }}>
                    {media?.type?.toLowerCase() === "video" && (
                      <div
                        className="mediaVideo"
                        data-toggle="modal"
                        data-target={"#exampleModal" + key_}>
                        <Video
                          id={media?.id ? media?.id : ""}
                          src={media?.url ? media?.url : ""}
                          className="border-20"
                          width="100%"
                          height="100%"
                          controls="controls"
                          // onClick={onClick}
                        />
                      </div>
                    )}
                    {media?.type?.toLowerCase() === "image" && (
                      <div
                        className="image-media-div"
                        data-toggle="modal"
                        data-target={"#exampleModal" + key_}>
                        <Image
                          className="border-20 image-media w-100"
                          src={media?.url ? media?.url : ""}
                        />
                      </div>
                    )}
                    {media?.type?.toLowerCase() === "file" && (
                      <div
                        className="file-media-div"
                        data-toggle="modal"
                        data-target={"#exampleModal" + key_}>
                        <Image
                          className="border-20 image-media file-icon cursor-pointer"
                          src={file}
                        />
                      </div>
                    )}
                  </div>
                );
              })}
        </div>
        {PersonalDevelopmentDetails?.media?.length > 4 && (
          <div className="text-right mt-3">
            <div
              className="row d-flex flex-row align-items-center justify-content-end cursor-pointer"
              onClick={() => {
                setIsExpand(!isExpand);
              }}>
              <div className="col-auto d-flex justify-content-center">
                <p className="green expand-collapse-text">
                  {isExpand ? "Collapse Show" : "Expand More"}
                </p>
              </div>
              <div className="col-auto d-flex justify-content-center p-0">
                {/* <Image
                  className={`rotate ${isExpand ? "active" : ""}`}
                  src={expand_collapse_arrow}
                /> */}
                <Icons
                  name="expand-collapse-arrow"
                  className={`rotate ${isExpand ? "active" : ""}`}
                  fill={user?.brandTheme?.themeColors?.primary}
                />
              </div>
            </div>
          </div>
        )}
        {/* <div className="mediaCards d-flex flex-wrap">
          <div className="mediaCard">
            <div className="mediaVideo">
              <Video
                id={"dsd" ? "dsd" : ""}
                src={
                  "https://routeresourcegroup.blob.core.windows.net/video/17078079115043B5BE552-90E1-434C-AA29-A0CAD8074356.mp4#t=0.001"
                }
                className="border-20"
                width="100%"
                height="100%"
                controls="controls"
                // onClick={onClick}
              />
            </div>
          </div>
          <div className="mediaCard">
            <div className="image-media-div">
              <Image
                className="border-20 image-media"
                src={
                  "https://routeresourcegroup.blob.core.windows.net/image/17168712091839243379C-B364-457F-9096-CAF08A963ED0.png"
                }
              />
            </div>
          </div>
          <div className="mediaCard">
            <div className="">
              <Video
                id={"dsd" ? "dsd" : ""}
                src={
                  "https://routeresourcegroup.blob.core.windows.net/video/17078079115043B5BE552-90E1-434C-AA29-A0CAD8074356.mp4#t=0.001"
                }
                className="border-20"
                width="100%"
                height="100%"
                controls="controls"
                // onClick={onClick}
              />
            </div>
          </div>
          <div className="mediaCard">
            <div className="">
              <Video
                id={"dsd" ? "dsd" : ""}
                src={
                  "https://routeresourcegroup.blob.core.windows.net/video/17078079115043B5BE552-90E1-434C-AA29-A0CAD8074356.mp4#t=0.001"
                }
                className="border-20"
                width="100%"
                height="100%"
                controls="controls"
                // onClick={onClick}
              />
            </div>
          </div>
          <div className="mediaCard">
            <div className="">
              <Video
                id={"dsd" ? "dsd" : ""}
                src={
                  "https://routeresourcegroup.blob.core.windows.net/video/17078079115043B5BE552-90E1-434C-AA29-A0CAD8074356.mp4#t=0.001"
                }
                className="border-20"
                width="100%"
                height="100%"
                controls="controls"
                // onClick={onClick}
              />
            </div>
          </div>
        </div> */}
      </div>
      <Modal
        key_={key_}
        media={PersonalDevelopmentDetails?.media}
        selectedMedia={selectedMedia}
        onPreviousClick={onPreviousClick}
        onNextClick={onNextClick}
        count={count}
      />
    </div>
  );
};

export default Card;
