import React from "react";
import { useSelector } from "react-redux";

const Course = () => {
  let { user } = useSelector((state) => state.user);

  // const courseData = [
  //   {
  //     courseName: "Sporting Excellence Professional Programme",
  //     gradeName: "Predicted grade",
  //     grade: "Distinction",
  //   },
  //   {
  //     courseName: "Sporting Excellence & Performance",
  //     gradeName: "Achieved grade",
  //     grade: "Distinction",
  //   },
  //   {
  //     courseName: "UEFA C Diploma",
  //     gradeName: "Predicted grade",
  //     grade: "Merit",
  //   },
  // ];
  return (
    <div>
      <h6 className="green bold marginBottomSmall currentStudies">
        Current Studies
      </h6>

      {user?.courseData?.length > 0 &&
        user?.courseData?.map((data) => (
          <>
            <p className="marginBottomSmall twoLineHeight">
              {data?.course ? data?.course : ""}
            </p>
            <p className="flexRow SB">
              {data?.gradeData?.gradeTitle ? data?.gradeData?.gradeTitle : ""}
              {/* Predicted grade */}
              <span className="green">{data?.gradeData?.grade}</span>
            </p>
            <div className="greenBreak marginBottomSmall"></div>
          </>
        ))}
    </div>
  );
};

export default Course;
