import React from "react";

const Heading = (props) => {
  const { name } = props;
  return (
    <div className="greenTitle flexRow marginBottom">
      <h3 className="bold title text-light">{name}</h3>
      <div className="whiteLine"></div>
    </div>
  );
};

export default Heading;
