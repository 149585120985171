import { stats_key } from "components/pages/Sports/TraditionalCv/components/FootballStats/constants";
import Heading from "components/pages/Sports/TraditionalCv/components/Heading/Heading";
import { useLayoutEffect } from "react";

import { loadCharts } from "./apexcharts";
import { useSelector } from "react-redux";

const FootballStats = () => {
  let { user } = useSelector((state) => state.user);

  const coachingProgramme = user?.coached_minutes_overall?.map((data) => {
    let seasonObj = user?.coached_minutes_this_season?.find((item) => {
      return data?.title.trim() === item?.title;
    });

    let coachingProgrammeData = {
      title: data?.title,
      seasonToDate: seasonObj?.value
        ? seasonObj?.value
        : seasonObj?.defaultValue,
      career: data?.value ? data?.value : data?.defaultValue,
    };
    return coachingProgrammeData;
  });

  let coachingProgrammeSeasonTotal = 0;
  let coachingProgrammeOverAllTotal = 0;
  coachingProgramme?.map((data) => {
    coachingProgrammeSeasonTotal += Number(data.seasonToDate);
    coachingProgrammeOverAllTotal += Number(data.career);
    return data;
  });

  const gamesProgramme_ = user?.game_time_overall?.map((data) => {
    let seasonObj = user?.game_time_season?.find((item) => {
      return data.title.trim() === item?.title;
    });

    let gamesProgrammeData = {
      title: data?.title,
      seasonToDate: seasonObj?.value
        ? seasonObj?.value
        : seasonObj?.defaultValue,
      career: data?.value ? data?.value : data?.defaultValue,
    };
    return gamesProgrammeData;
  });

  let gamesProgramme_SeasonTotal = 0;
  let gamesProgramme_OverAllTotal = 0;
  gamesProgramme_?.map((data) => {
    gamesProgramme_SeasonTotal += Number(data.seasonToDate);
    gamesProgramme_OverAllTotal += Number(data.career);
    return data;
  });

  // Appearances sum
  let appearances_seasonTotal =
    +user.goals_this_season + +user.assists_this_season;

  let appearances_overAllTotal = +user.goals_overall + +user.assists_overall;

  useLayoutEffect(() => {
    setTimeout(() => {
      loadCharts({
        appearances: {
          season: appearances_seasonTotal,
          overAll: appearances_overAllTotal,
        },
        coachedMinutes: {
          season: coachingProgrammeSeasonTotal,
          overAll: coachingProgrammeOverAllTotal,
        },
        gamesProgramme_Minutes: {
          season: gamesProgramme_SeasonTotal,
          overAll: gamesProgramme_OverAllTotal,
        },
      });
    }, 100);
    // eslint-disable-next-line
  }, [user]);
  return (
    <>
      <Heading name="Football Stats" />
      <div className="colGrid3 marginBottom">
        <div className="flexRow">
          {stats_key?.map((item) => (
            <>
              <div id={item?.id} className="keySquare"></div>
              <p>{item?.name}</p>
            </>
          ))}
        </div>
        <div></div>
        <div></div>
        <div>
          <h6 className="green bold">Appearances</h6>
          <div className="barChart" id="appearance-chart"></div>
        </div>
        <div>
          <h6 className="green bold">Minutes</h6>
          <div className="barChart" id="minutes-played-chart"></div>
        </div>
        <div>
          <h6 className="green bold">Coached Minutes</h6>
          <div className="barChart" id="coached-minutes-played-chart"></div>
        </div>
      </div>
    </>
  );
};

export default FootballStats;
