import Icons from "assets/icons/Icons";
import Image from "components/Image";
import { useSelector } from "react-redux";

const Card = ({ referenceTutorCommentData }) => {
  let { user } = useSelector((state) => state.user);

  return (
    <div
      id="tutor-comment-inner"
      className="tutor-comment-card d-flex flex-column gap-50 flex-unset justify-content-between">
      <div
        id="tutor-comment-details"
        className="tutor-comment-details d-flex flex-column gap-25">
        <div
          id="tutor-details"
          className="row d-flex flex-row p-0 flex-nowrap flex-unset gap-10 align-items-center">
          <div className="col-md-3 p-0">
            <Image
              className="tutor-image w-100"
              src={
                user?.cvOverview?.tutor?.image
                  ? user?.cvOverview?.tutor?.image
                  : "https://routeresourcegroup.blob.core.windows.net/image/user-avatar-placeholder.png"
              }
            />
          </div>
          <div className="col-md-9 d-flex flex-column p-0">
            <h5 id="tutor-name" className="green font-weight-bold">
              {user?.cvOverview?.tutor?.name}
            </h5>
            <p id="tutor-role" className="">
              {user?.cvOverview?.tutor?.designation}
            </p>
            <p id="tutor-club" className="">
              {user?.cvOverview?.tutor?.club?.name}
            </p>
          </div>
        </div>
        <div id="tutor-comment-section" className="">
          <p id="tutor-comment">{`“${user?.cvOverview?.tutorComments}”`}</p>
        </div>
      </div>
      <div className="contact-icons d-flex flex-row gap-25">
        {/* <a
          href="tel:1234567890"
          className="phone-icon-link contact-link d-flex justify-content-center align-items-center"
        >
          <Icons name="phone" />
        </a> */}
        <a
          href="mailto:email@email.com"
          className="phone-icon-link contact-link d-flex justify-content-center align-items-center">
          <Icons name="e-mail" fill={user?.brandTheme?.themeColors?.primary} />
        </a>
      </div>
    </div>
  );
};

export default Card;
