import React from "react";
import { useSelector } from "react-redux";
import Heading from "components/pages/Sports/TraditionalCv/components/Heading/Heading";

const WorkExperience = () => {
  let { user } = useSelector((state) => state.user);
  return (
    <>
      <Heading name="Work Experience" />
      <div className="colGrid3 marginBottom">
        {user?.trips_events_experiences?.length > 0 &&
          user?.trips_events_experiences?.map((data) => (
            <div>
              <h6 className="green bold marginBottomSmall">
                {data?.title ? data?.title : ""}
              </h6>
              <p>{data?.description ? data?.description : ""}</p>
            </div>
          ))}
      </div>
    </>
  );
};

export default WorkExperience;
