import React from "react";
import { useSelector } from "react-redux";
import Card from "components/Interests/Card/Card";

const Interests = () => {
  let { user } = useSelector((state) => state.user);

  const getInterests = () => {
    const interestsArray = [];
    for (var i = 0; i < user?.interests?.length; i += 3) {
      interestsArray.push(
        <div className="row w-100 d-flex gap-50 flex-nowrap interest-row">
          {user?.interests?.slice(i, i + 3)?.map((data) => (
            <div className="col-md-4 p-0 d-flex stretch flex-unset">
              <Card interestsCardData={data} />
            </div>
          ))}
        </div>
      );
    }
    return interestsArray;
  };

  return (
    <div id="interests" className="flex-fill d-flex flex-row gut-100 section">
      <div className="col d-flex flex-column align-items-start justify-content-center content-box">
        <h2 id="interests-title" className="text-right">
          My <span className="font-weight-bold">Interests</span>
        </h2>
        {getInterests()}
      </div>
    </div>
  );
};

export default Interests;
