import React from "react";

const SkillModel = ({ skill_categories, skillType }) => {
 
  return (
    <>
      <div className="d-flex flex-column gap-25">
        <p id={skill_categories?.htmlId} className="font-weight-bold">
          {skill_categories?.category}
        </p>
        <div className="row d-flex flex-row align-items-center flex-nowrap gap-50 skills-row">
          {skill_categories?.sub_skills?.map((data) => (
            <div className="col-md-2 d-flex flex-column gap-25 justify-content-center corner-model-box p-0 flex-unset skills-box physical-box">
              <p>{data?.title}</p>
              {skillType === "Physical" ? (
                <>
                  <p
                    className={`fcm-number  ${
                      data.value === 0
                        ? "white"
                        : data.value === 1
                        ? "red"
                        : data.value === 2
                        ? "orange"
                        : "green"
                    } d-flex justify-content-center align-items-center`}
                  >
                    {/* {`${data.value}s`} */}
                    {data.value ? `${data.value}s` : ""}
                  </p>
                </>
              ) : (
                <>
                  <p
                    className={`fcm-number  ${
                      data.value === 0
                        ? "white"
                        : data.value === 1
                        ? "red"
                        : data.value === 2
                        ? "orange"
                        : "green"
                    } d-flex justify-content-center align-items-center`}
                  >
                    {data.value === 0
                      ? "N / A"
                      : data.value === 1
                      ? "1-2"
                      : data.value === 2
                      ? "3-4"
                      : "5-6"}
                  </p>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default SkillModel;
