import { all, fork, put, takeLatest } from "redux-saga/effects";
import { getQuery, postQuery } from "utils/api";
import { actions } from "./action";
import { actions as loaderAction } from "redux/Loader/action";

export function* getUser() {
  yield takeLatest(actions.GET_USER_REQUEST, function* (data) {
    try {
      const response = yield getQuery(
        `user?id=${data.data}&cvType=${data?.cvType}`
      );
      if (!response.data.error) {
        yield put({
          type: actions.GET_USER_SUCCESS,
          payload: response.data.result,
        });
        yield put({
          type: loaderAction.CLOSE_LOADER_SUCCESS,
        });
      }
    } catch (e) {
      console.log(e);
      yield put({
        type: loaderAction.CLOSE_LOADER_SUCCESS,
      });
    }
  });
}

export function* getValidExpiryDate() {
  yield takeLatest(actions.CHECK_VALID_EXPIRY_DATE_REQUEST, function* (data) {
    try {
      const response = yield getQuery(`validate-expiry-date?id=${data.data}`);
      if (!response.data.error) {
        yield put({
          type: actions.CHECK_VALID_EXPIRY_DATE_SUCCESS,
          payload: response.data.result,
        });
        yield put({
          type: loaderAction.CLOSE_LOADER_SUCCESS,
        });
      }
    } catch (e) {
      console.log(e);
      yield put({
        type: loaderAction.CLOSE_LOADER_SUCCESS,
      });
    }
  });
}

export function* checkIsPassword() {
  yield takeLatest(actions.CHECK_PASSWORD_REQUEST, function* (data) {
    try {
      const response = yield getQuery(`isPassword?id=${data.data}`);
      if (!response.data.error) {
        yield put({
          type: actions.CHECK_PASSWORD_SUCCESS,
          payload: response.data.result,
        });
        yield put({
          type: loaderAction.CLOSE_LOADER_SUCCESS,
        });
      }
    } catch (e) {
      console.log(e);
      yield put({
        type: loaderAction.CLOSE_LOADER_SUCCESS,
      });
    }
  });
}

export function* submitPassword() {
  yield takeLatest(actions.SUBMIT_PASSWORD_REQUEST, function* ({ data }) {
    try {
      // console.log("data", data);
      const response = yield postQuery(`validate-security-code`, data);
      if (!response.data.error) {
        // console.log(response.data);
        yield put({
          type: actions.SUBMIT_PASSWORD_SUCCESS,
          payload: response.data,
        });
        yield put({
          type: loaderAction.CLOSE_LOADER_SUCCESS,
        });
      }
    } catch (e) {
      console.log(e);
      yield put({
        type: actions.CATCH_ERROR,
        payload: e.response.data.message,
      });
      yield put({
        type: loaderAction.CLOSE_LOADER_SUCCESS,
      });
    }
  });
}

export default function* userSaga() {
  yield all([
    fork(getUser),
    fork(getValidExpiryDate),
    fork(checkIsPassword),
    fork(submitPassword),
  ]);
}
