export const stats_key = [
  {
    class: "season",
    name: "Season",
  },
  {
    class: "career",
    name: "Career",
  },
];

export const stats_expandInfo_title = [
  // { name: "Appearances" },
  { name: "Minutes" },
];
