import $ from "jquery";

/* nav menu */
// $(document).ready(function () {
//   $(".nav-item").click(function () {
//     // Remove 'active' class from previously active item
//     $(".nav-item-text.active").removeClass("active");
//     $(".active-line.active").removeClass("active");
//     // console.log("nav clicked");

//     $(this).find(".active-line").addClass("active");
//     $(this).find(".nav-item-text").addClass("active");
//   });
// });

export const containerWidth = () => {
  const navMenu = document.getElementById("nav-menu");
  if (navMenu) {
    const mainContainer = document.getElementById("main-container");
    const menuWidth = navMenu.offsetWidth;
    if (mainContainer)
      mainContainer.style.setProperty("--menu-width", `${menuWidth}px`);
  }

  $(window).on("resize", function () {
    const mainContainer = document.getElementById("main-container");
    const menuWidth = navMenu.offsetWidth;
    mainContainer.style.setProperty("--menu-width", `${menuWidth}px`);
  });
};

/* scroll - nav menu */
// export const scrollNavMenu = () => {
window.addEventListener("scroll", function () {
  var sections = document.querySelectorAll(".section");
  var navItems = document.querySelectorAll(".nav-item");
  var currentSection = null;
  var previousSection = null;

  sections.forEach(function (section) {
    var sectionTop = section.offsetTop;
    var sectionHeight = section.clientHeight;

    if (window.pageYOffset >= sectionTop - sectionHeight / 3) {
      currentSection = section;
    }
  });

  if (currentSection && currentSection !== previousSection) {
    navItems.forEach(function (navItem) {
      var navItemText = navItem.querySelector(".nav-item-text");
      var activeLine = navItem.querySelector(".active-line");

      navItemText.classList.remove("active");
      activeLine.classList.remove("active");

      if (
        navItem.getAttribute("href").slice(1) ===
        currentSection.getAttribute("id")
      ) {
        navItemText.classList.add("active");
        activeLine.classList.add("active");

        if (window.innerWidth <= 768) {
          // Adjust the width as needed
          var navMenu = document.querySelector("#nav-menu");
          var activeItemOffsetLeft = navItem.offsetLeft;
          var activeItemWidth = navItem.offsetWidth;
          var navMenuWidth = navMenu.offsetWidth;
          var scrollLeft =
            activeItemOffsetLeft - navMenuWidth / 2 + activeItemWidth / 2;

          navMenu.scrollTo({
            left: scrollLeft,
            behavior: "smooth",
          });
        }
      }
    });

    // console.log(
    //   "Scrolled past section with ID:",
    //   currentSection.getAttribute("id")
    // );
    // Perform additional actions here based on the current section

    previousSection = currentSection;
  }
});
// };

/* hamburger menu */
export const hamburgerMenu = () => {
  $(".hamburger").click(function () {
    if (!$(".hamburger").hasClass("is-active")) {
      $(".hamburger").toggleClass("is-active");
      $("#nav-menu").addClass("show");
      $("#main-container").addClass("lighten");
      $("body").addClass("no-scroll");
    } else {
      $(".hamburger").removeClass("is-active");
      $("#nav-menu").removeClass("show");
      $("#main-container").removeClass("lighten");
      $("body").removeClass("no-scroll");
    }
  });
};

/* burger menu nav items click */
$(".nav-item").on("click", function () {
  var windowWidth = window.innerWidth;
  if (windowWidth <= 1280 && windowWidth >= 769) {
    $(".hamburger").removeClass("is-active");
    $("#nav-menu").removeClass("show");
    $("#main-container").removeClass("lighten");
    $("body").removeClass("no-scroll");
  }
});

document.querySelectorAll(".nav-item").forEach(function (anchor) {
  anchor.addEventListener("click", function (event) {
    event.preventDefault();

    var offset = 80; // Adjust the offset value as needed
    var target = document.querySelector(this.getAttribute("href"));

    if (target) {
      var targetPosition = target.offsetTop - offset;

      window.scrollTo({
        top: targetPosition,
        behavior: "smooth",
      });
    }
  });
});
