import expand_collapse_arrow from "assets/images/expand-collapse-arrow.svg";
import Image from "components/Image";
import placeHolderImgCourse from "assets/images/placeHolderImgCourse.png";
import { useEffect } from "react";
import Icons from "assets/icons/Icons";
import { useSelector } from "react-redux";
const Card = ({ cardData, index, setIsDisplayTitle }) => {
  const { user } = useSelector((state) => state.user);
  useEffect(() => {
    if (cardData?.module?.modules > 0 || cardData?.unit?.units > 0) {
      setIsDisplayTitle(true);
    }
  }, [cardData]);

  function checkAttachments(data) {
    // Check attachments in the main object
    if (data.attachments && data.attachments.length > 0) {
      return data;
    }

    // Check attachments in modulesData
    if (data.modulesData) {
      for (const module of data.modulesData) {
        if (module.attachments && module.attachments.length > 0) {
          return module;
        }

        // Check attachments in units of each module (if units exist)
        if (module.units) {
          for (const unit of module.units) {
            if (unit.attachments && unit.attachments.length > 0) {
              return unit;
            }
          }
        }
      }
    }
  }

  return (
    <>
      {!cardData?.displayUnits &&
        ((cardData.overallGradeData !== "Ongoing" &&
          cardData?.module?.modules === 0 &&
          cardData?.unit?.units === 0) ||
          (cardData.overallGradeData === "Ongoing" &&
            cardData?.module?.modules > 0) ||
          cardData?.unit?.units > 0 ||
          (cardData.overallGradeData !== "Ongoing" &&
            cardData?.module?.modules > 0) ||
          cardData?.unit?.units > 0 ||
          checkAttachments(cardData)) && (
          <div
            id={`card${index + 1}`}
            className="col-12 col-md-4 p-0 d-flex flex-column stretch flex-unset card-top">
            <div className="outer-card h-100 d-flex flex-column">
              <div className="position-absolute outer-card-bottom-hide"></div>
              <div id={`course-${index + 1}`} className="card education-card">
                <div className="card-body d-flex flex-column justify-content-between p-0">
                  <div className="row row-gap d-flex justify-content-center align-items-center gap-10 flex-nowrap">
                    <div className="col-auto p-0">
                      <Image
                        src={
                          cardData?.image
                            ? cardData?.image
                            : placeHolderImgCourse
                        }
                        className={`education-icon`}
                      />
                    </div>
                    <div className="col-md-9 p-0">
                      <p className="card-title m-0">
                        {cardData?.course ? cardData?.course : ""}
                      </p>
                    </div>
                  </div>
                  <div className="row d-flex justify-content-start align-items-center gap-10 flex-nowrap mb-4">
                    <div className="col-auto p-0">
                      <p className="card-title m-0">Overall Grade - </p>
                    </div>
                    <div className="col-auto p-0">
                      <h6 className="font-weight-bold green m-0 p-0 overAllGradeData">
                        {cardData?.overallGradeData}
                      </h6>
                    </div>
                  </div>
                  <div className="row unit-info-row">
                    <div className="col-6 p-0 unit-info-row-inner">
                      <div className="row">
                        <div className="col-12 p-0">
                          <p className="card-title m-0 p-0">Modules</p>
                        </div>
                      </div>
                      <div className="row row d-flex justify-content-start align-items-center number-section">
                        <div className="col-auto p-0 pr-3 complete-number-div">
                          <h6 className="completed-number font-weight-bold green m-0 p-0">
                            {cardData?.module?.modules
                              ? cardData?.module?.modules
                              : 0}
                          </h6>
                        </div>
                        <div className="col-auto p-0 from-div">
                          <p className="card-text m-0 p-0">from</p>
                          <h6 className="from-number font-weight-bold m-0 p-0">
                            {cardData?.module?.totalModules
                              ? cardData?.module?.totalModules
                              : 0}
                          </h6>
                        </div>
                      </div>
                    </div>
                    {cardData?.unit?.totalUnits > 0 && (
                      <div className="col-6 p-0 unit-info-row-inner">
                        <div className="row">
                          <div className="col-12 p-0">
                            <p className="card-title m-0 p-0">Units</p>
                          </div>
                        </div>

                        <div className="row row d-flex justify-content-start align-items-center number-section">
                          <div className="col-auto p-0 pr-3 complete-number-div">
                            <h6 className="completed-number font-weight-bold green m-0 p-0">
                              {cardData?.unit?.units
                                ? cardData?.unit?.units
                                : 0}
                            </h6>
                          </div>
                          <div className="col-auto p-0 from-div">
                            <p className="card-text m-0 p-0">from</p>
                            <h6 className="from-number font-weight-bold m-0 p-0">
                              {cardData?.unit?.totalUnits
                                ? cardData?.unit?.totalUnits
                                : 0}
                            </h6>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {cardData?.workExperienceHours?.totalHours > 0 && (
                    <div>
                      <div class="card__indicator">
                        <span class="card__indicator-amount">
                          {cardData?.workExperienceHours?.hours} /{" "}
                          {cardData?.workExperienceHours?.totalHours}
                        </span>
                        <span class="ml-1">hrs</span>
                      </div>
                      <div className="card__progress">
                        <progress
                          max={cardData?.workExperienceHours?.totalHours}
                          value={
                            cardData?.workExperienceHours?.hours
                          }></progress>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div
                // id="card1-expand"
                id={`card${index + 1}-expand`}
                className="expand-collapse-row d-flex flex-column cursor-pointer">
                <div className="row d-flex flex-row align-items-center justify-content-end">
                  <div className="col-auto d-flex justify-content-center">
                    <p className="green expand-collapse-text">Expand Info</p>
                  </div>
                  <div className="col-auto d-flex justify-content-center p-0">
                    {/* <Image
                      className={`rotate`}
                      src={expand_collapse_arrow ? expand_collapse_arrow : ""}
                    /> */}
                    <Icons
                      name="expand-collapse-arrow"
                      className={`rotate`}
                      fill={user?.brandTheme?.themeColors?.primary}
                    />
                  </div>
                </div>
                <div className="expand-collapse-seperator"></div>
              </div>
            </div>
          </div>
        )}

      {cardData?.displayUnits && (
        <div
          // id="card3"
          id={`card${index + 1}`}
          className="col-12 col-md-4 p-0 d-flex flex-column stretch flex-unset card-top">
          <div className="outer-card h-100">
            <div className="position-absolute outer-card-bottom-hide"></div>
            <div className="card education-card">
              <div className="card-body d-flex flex-column p-0">
                <div className="row row-gap d-flex justify-content-center align-items-center">
                  <div className="col-3 p-0">
                    <Image
                      src={
                        cardData?.image ? cardData?.image : placeHolderImgCourse
                      }
                      className="education-icon"
                    />
                  </div>
                  <div className="col-9 p-0">
                    <p className="card-title m-0">
                      {cardData?.title ? cardData?.title : ""}
                    </p>
                  </div>
                </div>

                <div className="row d-flex gap-25 flex-nowrap">
                  <div className="col-6 p-0 flex-unset">
                    <div className="row d-flex flex-column gap-25">
                      {cardData?.modulesName?.length > 0 &&
                        cardData?.modulesName?.map((item, index) =>
                          index % 2 === 0 ? (
                            <div className="col-12 education-title-box d-flex justify-content-center align-items-center active">
                              <p className="box-title active">
                                {item.name ? item.name : ""}
                              </p>
                            </div>
                          ) : (
                            ""
                          )
                        )}
                    </div>
                  </div>
                  <div className="col-6 p-0 flex-unset">
                    <div className="row d-flex flex-column gap-25">
                      {cardData?.modulesName?.length > 0 &&
                        cardData?.modulesName?.map((item, index) =>
                          index % 2 !== 0 ? (
                            <div className="col-12 education-title-box d-flex justify-content-center align-items-center active">
                              <p className="box-title active">
                                {item.name ? item.name : ""}
                              </p>
                            </div>
                          ) : (
                            ""
                          )
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id={`card${index + 1}-expand`}
              className="expand-collapse-row w-100 d-flex flex-column cursor-pointer">
              <div className="row d-flex flex-row align-items-center justify-content-end">
                <div className="col-auto d-flex justify-content-center">
                  <p className="green expand-collapse-text">Expand Info</p>
                </div>
                <div className="col-auto d-flex justify-content-center p-0">
                  <Image
                    className="rotate"
                    src={expand_collapse_arrow ? expand_collapse_arrow : ""}
                  />
                </div>
              </div>
              <div className="col-12 expand-collapse-seperator"></div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Card;
