import React from "react";
import Heading from "components/pages/Sports/TraditionalCv/components/Heading/Heading";
import { useSelector } from "react-redux";

const FourCornerModel = () => {
  let { user } = useSelector((state) => state.user);

  const skills = [
    {
      type: "Physical",
      categories: [
        {
          category: "Speed",
          sub_skills: user?.speed,
        },
        {
          category: "Agility",
          sub_skills: user?.agility,
        },
      ],
    },
  ];
  return (
    <>
      <Heading name="Physical Markers" />
      <div className="colGrid3 marginBottom">
        <div>
          {skills?.length > 0 &&
            skills?.map((data) => (
              <>
                {data?.categories?.map((item) => (
                  <>
                    <h6 className="bold marginBottomSmall">
                      <span className="green">
                        {`${data?.type ? data?.type : ""} >`}{" "}
                      </span>
                      {item?.category}
                    </h6>
                    {item?.sub_skills?.map((categories) => (
                      <>
                        <p className="flexRow SB marginBottomSmall twoLineHeight">
                          {categories?.title}
                          <span className="green">{categories?.value}</span>
                        </p>
                      </>
                    ))}
                  </>
                ))}
              </>
            ))}
        </div>
      </div>
      <div className="colGrid3 marginBottom">
        {user?.physical_attributes?.length > 0 &&
          user?.physical_attributes?.map((data) => (
            <div>
              <h6 className="green bold marginBottomSmall">
                {data?.title ? data?.title : ""}
              </h6>
              <p>{data?.description ? data?.description : ""}</p>
            </div>
          ))}
      </div>
    </>
  );
};

export default FourCornerModel;
