// import bio_video from "assets/videos/biovideo.mp4";
import VideoCard from "components/VideoCard";
import { bioVideo } from "components/VideoCard/VideoCard.Script";
import { useSelector } from "react-redux";

const BioVideo = () => {
  let { user } = useSelector((state) => state.user);

  return (
    <div
      id="bio-video"
      className="flex-fill d-flex flex-row gut-100 justify-content-center pt-20 section align-items-center flex-column gap-50"
    >
      <div className="col-12 p-0">
        <h2 id="highlights-gallery-title" className="text-left">
          My <span className="font-weight-bold">Bio Video</span>
        </h2>
      </div>
      <div className="col col-md-8 bio-video-container">
        {user?.bioVideo?.url && (
          <VideoCard
            id_="bio-video"
            subId="bio-video-items"
            videoSrc={`${
              user?.bioVideo?.url ? user?.bioVideo?.url + "#t=0.01" : ""
            }`}
            onClick={() => {
              bioVideo("bio-video", "bio-video-items");
            }}
            name={"Bio"}
          />
        )}
      </div>
    </div>
  );
};

export default BioVideo;
