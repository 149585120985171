import TraditionalCv from "components/pages/Sports/TraditionalCv";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { actions as userAction } from "redux/User/action";

const Traditional = () => {
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    dispatch(userAction.getUser(params.userId, "traditional"));
    // eslint-disable-next-line
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          media="print"
          href="/css/print_styles.css"
          id="theme-link"
        />
        <link
          rel="stylesheet"
          media="screen"
          href="/css/styles.css"
          id="theme-link"
        />
      </Helmet>
      <TraditionalCv />
    </>
  );
};

export default Traditional;
