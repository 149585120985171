/* appearances chart */
// const data = {appearance:[],..,};
export const loadCharts = (data) => {
  var optionsAppearances = {
    series: [
      {
        data: [data?.appearances?.season, data?.appearances?.overAll],
      },
    ],
    chart: {
      type: "bar",
      marginLeft: -20,
      height: 100,
      toolbar: {
        show: false,
      },
      animations: {
        enabled: false, // Set this to false to disable animations
      },
      grid: {
        show: false,
        borderColor: "#fff", // Set the grid border color to transparent
        strokeDashArray: 0,
      },
    },

    plotOptions: {
      bar: {
        barHeight: "70%",
        columnWidth: "70%",
        distributed: true,
        horizontal: true,
        hover: null,
        borderRadius: 0, // Set the radius for rounded corners
        dataLabels: {
          position: "top", // Set the data labels to appear on top of the bars
        },
        barPercentage: 0.6,
      },
    },
    colors: ["#999999", "#333333"],
    dataLabels: {
      enabled: true,
      animation: {
        enabled: false,
      },
      formatter: function (val) {
        if (val === optionsAppearances.series[0].data[0]) {
          return optionsAppearances.series[0].data[0]; // Display the value of the first data point
        } else if (val === optionsAppearances.series[0].data[1]) {
          return optionsAppearances.series[0].data[1]; // Display the value of the second data point
        } else {
          return "";
        }
      },
      style: {
        colors: ["#fff"],
        fontSize: "12px", // Increase the font size
        fontWeight: "400",
      },
      textAnchor: "start",
      offsetX: 50,
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    xaxis: {
      categories: ["", ""], // Add two empty categories for spacing
      labels: {
        show: false,
      },
      lines: {
        show: false,
        borderColor: "#fff",
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
      lines: {
        show: false,
        borderColor: "#fff",
      },
    },
    title: {
      text: "",
      align: "center",
      floating: true,
    },
    subtitle: {
      text: "",
      align: "center",
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      show: false,
    },
  };
  // eslint-disable-next-line no-undef
  var appearancechart = new ApexCharts(
    document.querySelector("#appearance-chart"),
    optionsAppearances
  );

  appearancechart.updateOptions({
    xaxis: {
      max:
        optionsAppearances.series[0].data[1] +
        optionsAppearances.series[0].data[1] * 0.3, // Set max to the second data point + 20%
    },
  });

  var chartContainer = document.querySelector("#appearance-chart");

  chartContainer.style.position = "relative";
  chartContainer.style.left = "-30px";

  // minutes played chart
  var optionsMinutes = {
    series: [
      {
        data: [
          data?.gamesProgramme_Minutes?.season,
          data?.gamesProgramme_Minutes?.overAll,
        ],
      },
    ],
    chart: {
      type: "bar",
      height: 100,
      toolbar: {
        show: false,
      },
      animations: {
        enabled: false, // Set this to false to disable animations
      },
      grid: {
        show: false,
        borderColor: "#fff", // Set the grid border color to transparent
        strokeDashArray: 0,
      },
    },
    plotOptions: {
      bar: {
        barHeight: "70%",
        distributed: true,
        horizontal: true,
        borderRadius: 0,
        hover: null, // Set the radius for rounded corners
        dataLabels: {
          position: "top", // Set the data labels to appear on top of the bars
        },
        barWidth: "80%", // Set the bar width as a percentage
      },
    },
    colors: ["#999999", "#333333"],
    animation: {
      enabled: false,
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        if (val === optionsMinutes.series[0].data[0]) {
          return optionsMinutes.series[0].data[0]; // Display the value of the first data point
        } else if (val === optionsMinutes.series[0].data[1]) {
          return optionsMinutes.series[0].data[1]; // Display the value of the second data point
        } else {
          return "";
        }
      },
      style: {
        colors: ["#fff"],
        fontSize: "12px", // Increase the font size
        fontWeight: "400",
      },
      textAnchor: "start",
      offsetX: 50,
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    xaxis: {
      categories: ["", ""], // Add two empty categories for spacing
      labels: {
        show: false,
      },
      lines: {
        show: false,
        borderColor: "#fff",
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
      lines: {
        show: false,
        borderColor: "#fff",
      },
    },
    title: {
      text: "",
      align: "center",
      floating: true,
    },
    subtitle: {
      text: "",
      align: "center",
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      show: false,
    },
  };

  // eslint-disable-next-line no-undef
  var minutesplayed = new ApexCharts(
    document.querySelector("#minutes-played-chart"),
    optionsMinutes
  );

  minutesplayed.updateOptions({
    xaxis: {
      max:
        optionsMinutes.series[0].data[1] +
        optionsMinutes.series[0].data[1] * 0.3, // Set max to the second data point + 20%
    },
  });

  var minuteschart = document.querySelector("#minutes-played-chart");
  minuteschart.style.position = "relative";
  minuteschart.style.left = "-30px";

  // coached minutes
  var optionscoachMinutes = {
    series: [
      {
        data: [data?.coachedMinutes?.season, data?.coachedMinutes?.overAll],
      },
    ],
    chart: {
      type: "bar",
      height: 100,
      toolbar: {
        show: false,
      },
      animations: {
        enabled: false, // Set this to false to disable animations
      },
      grid: {
        show: false,
        borderColor: "#fff", // Set the grid border color to transparent
        strokeDashArray: 0,
      },
    },
    plotOptions: {
      bar: {
        barHeight: "70%",
        distributed: true,
        horizontal: true,
        borderRadius: 0,
        hover: null, // Set the radius for rounded corners
        dataLabels: {
          position: "top", // Set the data labels to appear on top of the bars
        },
        barWidth: "80%", // Set the bar width as a percentage
      },
    },
    colors: ["#999999", "#333333"],
    dataLabels: {
      animation: {
        enabled: false,
      },
      enabled: true,
      formatter: function (val) {
        if (val === optionscoachMinutes.series[0].data[0]) {
          return optionscoachMinutes.series[0].data[0]; // Display the value of the first data point
        } else if (val === optionscoachMinutes.series[0].data[1]) {
          return optionscoachMinutes.series[0].data[1]; // Display the value of the second data point
        } else {
          return "";
        }
      },
      style: {
        colors: ["#fff"],
        fontSize: "12px", // Increase the font size
        fontWeight: "400",
      },
      textAnchor: "start",
      offsetX: 50,
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    xaxis: {
      categories: ["", ""], // Add two empty categories for spacing
      labels: {
        show: false,
      },
      lines: {
        show: false,
        borderColor: "#fff",
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
      lines: {
        show: false,
        borderColor: "#fff",
      },
    },
    title: {
      text: "",
      align: "center",
      floating: true,
    },
    subtitle: {
      text: "",
      align: "center",
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      show: false,
    },
  };

  // eslint-disable-next-line no-undef
  var coachMinutes = new ApexCharts(
    document.querySelector("#coached-minutes-played-chart"),
    optionscoachMinutes
  );
  coachMinutes.updateOptions({
    xaxis: {
      max:
        optionscoachMinutes.series[0].data[1] +
        optionscoachMinutes.series[0].data[1] * 0.3, // Set max to the second data point + 20%
    },
  });

  var coachminuteschart = document.querySelector(
    "#coached-minutes-played-chart"
  );
  coachminuteschart.style.position = "relative";
  coachminuteschart.style.left = "-30px";

  appearancechart.render();
  minutesplayed.render();
  coachMinutes.render();

  /* transferable values */
  function generateChartOptions(value) {
    var minAngle = -135; // Start angle
    var maxAngle = 135; // End angle

    var adaptoptions = {
      series: [value * 10],
      chart: {
        width: "100%",
        type: "radialBar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: minAngle,
          endAngle: maxAngle,
          hollow: {
            margin: 10,
            size: "85%",
            background: "#fff",
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: "front",
            dropShadow: {
              enabled: false,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24,
            },
          },
          track: {
            background: "#fff",
            strokeWidth: "0%",
            margin: 0, // margin is in pixels
            dropShadow: {
              enabled: false,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.35,
            },
          },
          dataLabels: {
            show: true, // Hide the default series label
            value: {
              formatter: function (val) {
                return value; // Display the original value as the label
              },
              color: "#006b00",
              fontSize: "60px",
              fontWeight: "700",
              show: true,
            },
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "horizontal",
          shadeIntensity: 0,
          gradientFromColors: ["#65a8d1"],
          gradientToColors: ["#006b00"],
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      stroke: {
        lineCap: "round",
      },
    };

    return adaptoptions;
  }

  // Generate options for each chart
  var adaptabilityvalue = 6;
  var adaptabilityoptions = generateChartOptions(adaptabilityvalue);

  var assertivenessvalue = 8;
  var assertivenessoptions = generateChartOptions(assertivenessvalue);

  var acceptancevalue = 10;
  var acceptanceoptions = generateChartOptions(acceptancevalue);

  var considerationvalue = 7;
  var considerationoptions = generateChartOptions(considerationvalue);

  // Create charts
  // eslint-disable-next-line no-undef
  var adaptability1 = new ApexCharts(
    document.querySelector("#adaptability-chart"),
    adaptabilityoptions
  );
  adaptability1.render();

  // eslint-disable-next-line no-undef
  var assertiveness = new ApexCharts(
    document.querySelector("#assertiveness-chart"),
    assertivenessoptions
  );
  assertiveness.render();

  // eslint-disable-next-line no-undef
  var acceptance = new ApexCharts(
    document.querySelector("#acceptance-chart"),
    acceptanceoptions
  );
  acceptance.render();

  // eslint-disable-next-line no-undef
  var consideration = new ApexCharts(
    document.querySelector("#consideration-chart"),
    considerationoptions
  );
  consideration.render();
};
