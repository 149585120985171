import React, { useEffect, useState } from "react";
import Heading from "components/pages/Sports/TraditionalCv/components/Heading/Heading";
import Image from "components/Image";
import { useSelector } from "react-redux";

const PreviousClubs = () => {
  let { user } = useSelector((state) => state.user);

  const [previousClubs, setPreviousClubs] = useState([]);

  useEffect(() => {
    let clubs =
      user?.previous_clubs?.length > 0
        ? user?.previous_clubs.sort((a, b) => {
            return Number(a.startDate) - Number(b.startDate);
          })
        : [];
    setPreviousClubs([...clubs]);
  }, [user]);

  return (
    <>
      <Heading name="Previous Clubs" />
      <div className="colGrid3 marginBottom">
        {previousClubs?.length > 0 &&
          previousClubs
            ?.slice(previousClubs?.length - 3, previousClubs?.length)
            ?.reverse()
            ?.map((data) => (
              <div className="rightCol">
                <div className="flexRow marginBottomSmall">
                  <Image
                    className="prevClubLogo"
                    src={data?.image ? data?.image : ""}
                  />

                  <h6 className="green bold">{data?.name ? data?.name : ""}</h6>
                </div>
                <p className="flexRow SB">
                  {data?.startDate ? data?.startDate : ""}
                  <span>{data?.endDate ? data?.endDate : ""}</span>
                </p>
                <div className="greenBreak marginBottomSmall"></div>
              </div>
            ))}
      </div>
    </>
  );
};

export default PreviousClubs;
