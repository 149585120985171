import React from "react";
import { useSelector } from "react-redux";

const GCSE = () => {
  let { user } = useSelector((state) => state.user);

  // const GgseData_ = user?.key_stage_4_qualification_compulsory.concat(
  //   user?.key_stage_4_qualification_optional
  // );

  return (
    <div>
      <h6 className="green bold marginBottomSmall">
        KEY STAGE 4 / GCSE QUALIFICATIONS
      </h6>
      <h6 className="green bold marginBottomSmall">Compulsory</h6>
      {user?.key_stage_4_qualification_compulsory?.length > 0 &&
        user?.key_stage_4_qualification_compulsory?.map((data) => (
          <p className="flexRow SB">
            {data?.title ? data?.title : ""}
            <span className="green">{data?.value ? data?.value : ""}</span>
          </p>
        ))}

      <h6 className="green bold marginBottomSmall pt-15">Optional</h6>
      {user?.key_stage_4_qualification_optional?.length > 0 &&
        user?.key_stage_4_qualification_optional?.map((data) => (
          <p className="flexRow SB">
            {data?.title ? data?.title : ""}
            <span className="green">{data?.value ? data?.value : ""}</span>
          </p>
        ))}
    </div>
  );
};

export default GCSE;
