import React from "react";

const Card = ({ data }) => {
  return (
    // <div className="personal-development-card w-100 d-flex flex-column">
    //   <h5 className="green font-weight-bold">
    //     {PersonalDevelopmentDetails?.title}
    //   </h5>
    //   <p>{PersonalDevelopmentDetails?.description}</p>
    // </div>
    <div className="col-md-2 d-flex flex-column gap-25 justify-content-center corner-model-box p-0 flex-unset skills-box physical-box">
      <p>{data?.title}</p>
      <p
        className={`fcm-number green d-flex justify-content-center align-items-center`}
      >
        {data?.value}
      </p>
    </div>
  );
};

export default Card;
