// src/Form.js

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { actions as loaderAction } from "redux/Loader/action";
import { actions as userAction } from "redux/User/action";
import "./PasswordForm.css";

const PasswordForm = () => {
  // State to store the password
  const params = useParams();
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  let { error } = useSelector((state) => state.user);

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Here, you can perform any actions with the password, such as sending it to a server
    // Clear the password field
    dispatch(loaderAction.startLoader());
    dispatch(
      userAction.submitPassword({ id: params.userId, securityCode: password })
    );
    setPassword("");
  };

  // Handle password field changes
  const handleChange = (e) => {
    setPassword(e.target.value);
  };

  return (
    <div id="containerSecurityForm">
      <form onSubmit={handleSubmit} className="wrapper">
        <div className="d-flex wrap">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            name="password"
            value={password}
            onChange={handleChange}
            className="input-box p-2"
            // id="security-code"
            placeholder="Enter Password"
          />

          <p className="text-danger">
            {" "}
            <small>{error}</small>
          </p>
        </div>
        <div className="input-box button mt-3" id="submitBtn">
          <button type="submit" className="btn btn-success btn-block">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default PasswordForm;
