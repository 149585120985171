const { actions } = require("./action");

const initialState = {
  user: null,
  error: "",
  validExpiryDate: null,
  isPassword: null,
};

//test

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
      };
    case actions.CHECK_VALID_EXPIRY_DATE_SUCCESS:
      return {
        ...state,
        validExpiryDate: action.payload,
      };
    case actions.CHECK_PASSWORD_SUCCESS:
      return {
        ...state,
        isPassword: action.payload,
      };
    case actions.SUBMIT_PASSWORD_SUCCESS:
      return {
        ...state,
        validExpiryDate: action.payload,
        isPassword: false,
      };

    case actions.CATCH_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default userReducer;
