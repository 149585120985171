import $ from "jquery";
/* popup */

export const popUp = () => {
  $(document).ready(function () {
    $("#contact-me-btn").click(function () {
      $("#popup").addClass("active");
      $("#main-container").addClass("lighten");
      $("#nav-menu").addClass("lighten");
    });
  });
  $(document).ready(function () {
    $("#popup-close").click(function () {
      $("#popup").removeClass("active");
      $("#main-container").removeClass("lighten");
      $("#nav-menu").removeClass("lighten");
    });
  });
};
