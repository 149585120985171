import $ from "jquery";

export const eductionScript = () => {
  /* education expand */
  $(document).ready(function () {
    var headerHeight = 0; // Initialize header height

    // Calculate header height on page load
    calculateHeaderHeight();

    // Recalculate header height on window resize
    $(window).resize(function () {
      calculateHeaderHeight();
    });

    function calculateHeaderHeight() {
      var header = document.getElementById("nav-menu");
      var windowWidth = window.innerWidth;

      if (windowWidth <= 768) {
        headerHeight = header.offsetHeight + 20;
        document.body.style.paddingTop = 70 + "px";
      } else {
        headerHeight = 0;
        document.body.style.paddingTop = 0;
      }
    }

    /* fixed header 
  window.addEventListener('scroll', function() {
    var nav = document.getElementById('nav-menu');
    var navPosition = nav.offsetTop; // Get the position of the navigation menu
    var education = document.getElementById('education');
    var educationPosition = education.offsetTop; // Get the position of the education section
  
    if (window.pageYOffset >= navPosition) {
      nav.classList.add('fixed'); // Add the 'fixed' class when scrolling past navPosition
    }
    if (!(window.pageYOffset >= educationPosition)) {
      nav.classList.remove('fixed'); // Remove the 'fixed' class when not scrolling past educationPosition
    }
  
  });
  */

    $(".nav-item").click(function () {
      if ($(window).width() <= 768) {
        if ($(".card").hasClass("active")) {
          // Remove all active classes
          $(".active").removeClass("active");
          $(".education-expand-tile").addClass("hidden");
          $(".expand-collapse-row .expand-collapse-text").text("Expand Info");
          $(".card-top").removeClass("hidden");
          // Enable scrolling
          $("body").removeClass("no-scroll");
        }
      }
    });

    function toggleAttachments(card) {
      $(".education-unit").removeClass("active");
      $(".card-body").removeClass("active");
      $(".education-attachment").addClass("hidden");

      var attachments = card.find(".unit-attachments");
      card.find(".card-body").addClass("active");
      attachments.removeClass("hidden");

      var bodyId = card.attr("id");
      // console.log(bodyId);
      $("#" + bodyId + "-attachments").removeClass("hidden");
    }

    $(".outer-card").on("click", ".education-card", function () {
      var card = $(this).closest(".education-card");
      if (card.hasClass("active")) {
        toggleAttachments(card);
      }
    });

    $(".expand-collapse-row").click(function () {
      var card = $(this).closest(".outer-card");
      var educard = card.find(".education-card");
      var rotateElement = $(this).find(".rotate");

      // Hide other expanded tiles
      $(".education-expand-tile").addClass("hidden");

      if (card.hasClass("active")) {
        // Collapse the card
        card.removeClass("active");
        card.find(".education-card").removeClass("active");
        card.find(".outer-card-bottom-hide").removeClass("active");
        card.find(".card-body").removeClass("active");
        $(this).removeClass("active"); // Remove active class from clicked expand-collapse-row
        $(this).find(".expand-collapse-text").text("Expand Info");
        rotateElement.removeClass("active");

        // Check screen width
        if ($(window).width() <= 768) {
          // Show other card-top divs
          $(".card-top").removeClass("hidden");
          // Disable page scrolling
          $("body").removeClass("no-scroll");
        }
      } else {
        // Collapse any previously expanded cards
        $(".outer-card.active").removeClass("active");
        $(".education-card.active").removeClass("active");
        $(".outer-card-bottom-hide.active").removeClass("active");
        $(".card-body.active").removeClass("active");
        $(".expand-collapse-row.active").removeClass("active"); // Remove active class from other expand-collapse-row instances
        $(".expand-collapse-row .expand-collapse-text").text("Expand Info");
        $(".rotate.active").removeClass("active");

        // Check screen width
        if ($(window).width() <= 768) {
          // Hide other card-top divs
          $(".card-top").not(card.parents(".card-top")).addClass("hidden");
          // Disable page scrolling
          $("body").addClass("no-scroll");
        }

        // Expand the current card
        card.addClass("active");
        card.find(".education-card").addClass("active");
        card.find(".outer-card-bottom-hide").addClass("active");
        card.find(".card-body").addClass("active");
        $(this).addClass("active"); // Add active class to clicked expand-collapse-row
        $(this).find(".expand-collapse-text").text("Collapse Info");
        rotateElement.addClass("active");
        // Show the corresponding expanded tile
        var cardParentId = card.parent().attr("id");
        $("#" + cardParentId + "-expanded-tile").removeClass("hidden");

        toggleAttachments(educard);

        var cardOffset = card.offset().top;
        if ($(window).width() <= 768) {
          cardOffset -= headerHeight;
        }
        $("html, body").scrollTop(cardOffset);
      }

      // Calculate section heights
      if ($(window).width() <= 768) {
        var paddingValue = 30;
        var section2Height = $(".education-card.active").outerHeight();

        // Calculate remaining height
        var remainingHeight =
          $(window).height() - (headerHeight + section2Height + paddingValue);
        // console.log("remaining = ", remainingHeight);
        // Apply height to the fourth section
        $(".education-expand-tile").css("max-height", remainingHeight + "px");
        $(".education-expand-tile").css("min-height", remainingHeight + "px");
      }
    });
  });

  $(document).ready(function () {
    // Get all the module buttons
    const moduleButtons = $(".module");

    // Attach a click event listener to each button
    moduleButtons.on("click", function () {
      // Get the ID of the module clicked
      const moduleId = $(this).attr("id");
      // Find the closest education attachment
      const educationAttachment = $(this).closest(".education-attachment-list");
      // Hide all education attachments except the clicked one
      $(".education-attachment-list").addClass("hidden");
      educationAttachment.removeClass("hidden");
      var moduleattachments = $("#" + moduleId + "-attachments");

      // Show the clicked education attachment
      moduleattachments.removeClass("hidden");

      // Add 'active' class to the clicked module button
      moduleButtons.removeClass("active");
      $(this).addClass("active");
    });
  });

  /* units / attachments toggle */
  $(document).ready(function () {
    const moduleButtons = $(".module");
    // Click event for education-unit
    $(".education-unit").on("click", function () {
      var unit = $(this);
      var unitId = unit.attr("id");

      $(".education-attachment").addClass("hidden");
      $("#" + unitId + "-attachments").removeClass("hidden");
      $(".education-attachment-list").addClass("hidden");
      $("#" + unitId + "-attachments-list").removeClass("hidden");

      $(".card-body").removeClass("active");
      $(".education-unit").not(unit).removeClass("active");

      unit.addClass("active");
      moduleButtons.removeClass("active");
    });
  });
};
