import ReferencesCard from "components/ReferencesCard";
import { useSelector } from "react-redux";
import Card from "components/References/Card/Card";

const References = () => {
  let { user } = useSelector((state) => state.user);

  return (
    <div id="references" className="flex-fill d-flex flex-row gut-100 section">
      <div className="col d-flex flex-column align-items-start justify-content-center gap-50">
        <h2 id="references-title" className="text-right">
          My <span className="font-weight-bold">References</span>
        </h2>
        <div className="d-flex flex-row gap-50 p-0 w-100 reference-inner">
          <div
            id="tutor-comment"
            className="col-6 d-flex flex-column gap-25 flex-unset p-0"
          >
            {user?.cvOverview && Object.keys(user.cvOverview).length > 0 && (
              <Card />
            )}
          </div>
          <div
            id="reference-list"
            className="col-6 d-flex flex-column gap-25 p-0 flex-unset"
          >
            {user?.references?.length > 0 &&
              user?.references?.map((data, i) => (
                <ReferencesCard key={i} tutorDetails={data} index={i} />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default References;
