import Image from "components/Image";
import VideoCard from "components/VideoCard";
import React from "react";
import { useSelector } from "react-redux";
import audio from "assets/images/audio_icon.svg";
import file from "assets/images/file_icon.png";

const Attachment = ({ attachmentsData }) => {
  const { user } = useSelector((state) => state.user);

  const playAudio = (id) => {
    const audio = document.getElementById(id);
    if (audio.paused) {
      audio.play();
    } else {
      audio.pause();
      audio.currentTime = 0;
    }
  };

  return (
    <>
      {attachmentsData?.tutorComments && (
        <div className="tutor-comment-attachment attachment-row align-items-start d-flex flex-row gap-50 w-100 flex-nowrap">
          <div className="col-md-6 flex-unset flex-row d-flex p-0 gap-10">
            <div className="col-md-3 flex-unset flex-row d-flex unit p-0 align-items-start col-auto">
              <Image
                src={
                  attachmentsData?.tutor?.image
                    ? attachmentsData?.tutor?.image
                    : "https://routeresourcegroup.blob.core.windows.net/image/user-avatar-placeholder.png"
                }
                className="attachment-image"
              />
            </div>
            <div className="col-md-9 d-flex flex-column align-items-start p-0 justify-content-center">
              <p className="tutor-name green font-weight-bold">
                {attachmentsData?.tutor?.name
                  ? attachmentsData?.tutor?.name
                  : ""}
              </p>
              <p className="tutor-role">
                {attachmentsData?.tutor?.designation
                  ? attachmentsData?.tutor?.designation
                  : ""}
              </p>
              <p className="tutor-club">
                {attachmentsData?.tutor?.club?.name
                  ? attachmentsData?.tutor?.club?.name
                  : ""}
              </p>
            </div>
          </div>
          <div className="col-md-6 flex-unset flex-row d-flex p-0 align-items-center">
            <div className="col-md-12 d-flex flex-row align-items-start p-0 justify-content-center gap-50">
              <p className="tutor-comment">
                {attachmentsData?.tutorComments
                  ? attachmentsData?.tutorComments
                  : ""}
              </p>
            </div>
          </div>
        </div>
      )}

      {attachmentsData?.type === "text" && (
        <div className="player-comment-attachment attachment-row align-items-start d-flex flex-row gap-50 w-100 flex-nowrap">
          <div className="col-md-6 flex-unset flex-row d-flex p-0 gap-10">
            <div className="col-md-3 flex-unset flex-row d-flex unit p-0 align-items-center col-auto">
              <Image
                src={
                  user?.profile_image
                    ? user?.profile_image
                    : "https://routeresourcegroup.blob.core.windows.net/image/user-avatar-placeholder.png"
                }
                className="attachment-image"
              />
            </div>
            <div className="col-md-9 d-flex flex-column align-items-start p-0 justify-content-center">
              <p className="player-name green font-weight-bold">
                {user?.name ? user?.name : ""}
              </p>
              <p className="personal-statement-title">{"Personal Statement"}</p>
            </div>
          </div>
          <div className="col-md-6 flex-unset flex-row d-flex p-0 align-items-center">
            <div className="col-md-12 d-flex flex-column align-items-start p-0 justify-content-center">
              <p className="player-comment-title green font-weight-bold">
                {attachmentsData?.title ? attachmentsData?.title : ""}
              </p>
              <p className="player-comment">
                {attachmentsData?.comments ? attachmentsData?.comments : ""}
              </p>
            </div>
          </div>
        </div>
      )}

      {/* {attachmentsData?.type === "text" && (
        <div className="player-audio-attachment attachment-row align-items-start d-flex flex-row gap-50 w-100 flex-nowrap">
          <div className="col-md-6 flex-unset flex-row d-flex p-0 gap-10">
            <div className="col-md-3 flex-unset flex-row d-flex unit p-0 align-items-center col-auto">
              <Image
                className="audio-icon attachment-image"
                src={
                  attachmentsData?.media_path ? attachmentsData?.media_path : ""
                }
              />
            </div>
            <div className="col-md-9 d-flex flex-column align-items-start p-0 justify-content-center">
              <p className="audio-name green font-weight-bold">s
                {attachmentsData?.title ? attachmentsData?.title : ""}
              </p>
            </div>
          </div>
          <div className="col-md-6 flex-unset flex-row d-flex p-0 align-items-center">
            <div className="col-md-12 d-flex flex-column align-items-start p-0 justify-content-center">
              <p className="audio-description">
                {attachmentsData?.description
                  ? attachmentsData?.description
                  : ""}
              </p>
            </div>
          </div>
        </div>
      )} */}

      {attachmentsData?.type === "file" && (
        <div className="player-audio-attachment attachment-row align-items-start d-flex flex-row gap-50 w-100 flex-nowrap">
          <div className="col-md-6 flex-unset flex-row d-flex p-0 gap-10">
            <div className="col-md-3 flex-unset flex-row d-flex unit p-0 align-items-center col-auto">
              <Image
                className="file-icon"
                // src={
                //   attachmentsData?.media_path
                //     ? attachmentsData?.media_path
                //     : file
                // }
                src={file}
              />
            </div>
            <div className="col-md-9 d-flex flex-column align-items-start p-0 justify-content-center">
              <p className="audio-name green font-weight-bold">
                {/* Audio Track Title */}
                <a
                  target={"_blank"}
                  href={attachmentsData?.media_path}
                  className="green font-weight-bold"
                >
                  {attachmentsData?.title ? attachmentsData?.title : ""}
                </a>
              </p>
            </div>
          </div>
          <div className="col-md-6 flex-unset flex-row d-flex p-0 align-items-center">
            <div className="col-md-12 d-flex flex-column align-items-start p-0 justify-content-center">
              <p className="audio-description">
                {attachmentsData?.description
                  ? attachmentsData?.description
                  : ""}
              </p>
            </div>
          </div>
        </div>
      )}

      {attachmentsData?.type === "audio" && (
        <div className="player-audio-attachment attachment-row align-items-start d-flex flex-row gap-50 w-100 flex-nowrap">
          <div className="col-md-6 flex-unset flex-row d-flex p-0 gap-10">
            <div className="col-md-3 flex-unset flex-row d-flex unit p-0 align-items-center col-auto">
              <Image
                className="audio-icon attachment-image"
                src={audio}
                onClick={() => {
                  playAudio(attachmentsData?.id);
                }}
                style={{
                  cursor: "pointer",
                }}
              />
            </div>
            <div className="col-md-9 d-flex flex-column align-items-start p-0 justify-content-center">
              <audio
                controls
                className="hidden"
                // id="${data.id}${index}"
                id={attachmentsData?.id}
                src={
                  attachmentsData?.media_path ? attachmentsData?.media_path : ""
                }
                type="audio/mpeg"
              ></audio>

              <p
                className="audio-name green font-weight-bold"
                onClick={() => {
                  attachmentsData?.title && playAudio(attachmentsData?.id);
                }}
                style={{
                  cursor: "pointer",
                }}
              >
                {/* Audio Track Title */}
                {attachmentsData?.title ? attachmentsData?.title : ""}
              </p>
            </div>
          </div>
          <div className="col-md-6 flex-unset flex-row d-flex p-0 align-items-center">
            <div className="col-md-12 d-flex flex-column align-items-start p-0 justify-content-center">
              <p className="audio-description">
                {attachmentsData?.description
                  ? attachmentsData?.description
                  : ""}
              </p>
            </div>
          </div>
        </div>
      )}

      {attachmentsData?.type === "image" && (
        <div className="player-image-attachment attachment-row align-items-start d-flex flex-row gap-50 w-100 flex-nowrap">
          <div className="col-md-6 flex-unset flex-row d-flex p-0 gap-10">
            <div className="col-md-12 flex-unset flex-row d-flex unit p-0 align-items-center">
              <Image
                className="image-attachment"
                src={
                  attachmentsData?.media_path ? attachmentsData?.media_path : ""
                }
              />
            </div>
          </div>
          <div className="col-md-6 flex-unset flex-row d-flex p-0 align-items-center">
            <div className="col-md-12 d-flex flex-column align-items-start p-0 justify-content-center">
              <p className="image-name green font-weight-bold">
                {attachmentsData?.title ? attachmentsData?.title : ""}
              </p>
              <p className="image-description">
                {attachmentsData?.description
                  ? attachmentsData?.description
                  : ""}
              </p>
            </div>
          </div>
        </div>
      )}

      {attachmentsData?.type === "video" && (
        <div className="player-video-attachment attachment-row align-items-start d-flex flex-row gap-50 w-100 flex-nowrap">
          <div className="col-md-6 flex-unset flex-row d-flex p-0 gap-10">
            <div className="col highlight-video d-flex flex-column gap-25 d-flex stretch flex-unset">
              <VideoCard
                videoSrc={
                  attachmentsData?.media_path
                    ? attachmentsData?.media_path + "#t=0.01"
                    : ""
                }
              />
            </div>
          </div>
          <div className="col-md-6 flex-unset flex-row d-flex p-0 align-items-center">
            <div className="col-md-12 d-flex flex-column align-items-start p-0 justify-content-center">
              <p className="image-name green font-weight-bold">
                {attachmentsData?.title ? attachmentsData?.title : ""}
              </p>
              <p className="image-description">
                {attachmentsData?.description
                  ? attachmentsData?.description
                  : ""}
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Attachment;
