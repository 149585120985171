import "assets/css/main.css";
import menu_logo from "assets/images/menu_logo.svg";
import Image from "components/Image";
import Loader from "components/Loader";
import InteractiveCv from "components/pages/Sports/InteractiveCv";
import { tabsData } from "layout/CvLayout/constants";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useLocation } from "react-router-dom";
import { actions as loaderAction } from "redux/Loader/action";
import { actions as userAction } from "redux/User/action";
import "./Interactive.Script";
import { containerWidth, hamburgerMenu } from "./Interactive.Script";
import qs from "query-string";
import CryptoJS from "crypto-js";
import PasswordForm from "components/PasswordForm/PasswordForm";
import CTRGladman from "crypto-js/mode-ctr-gladman";

const Interactive = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const parsed = qs.parse(location.search);
  // const value = queryString.parse(this.props.location.search);
  // const token = value.expiry;
  // console.log("token", token); //123
  // const query = new URLSearchParams(location.search);
  // console.log(query);
  let { user, validExpiryDate, isPassword } = useSelector(
    (state) => state.user
  );
  const { networkProgressDialog } = useSelector((state) => state.loader);

  const [tab, setTab] = useState(tabsData[0].key);
  const [decryptType, setDecryptType] = useState("");
  const [interactiveCvTabdata, setInteractiveCvTabdata] = useState(tabsData);
  const [showStats, setShowStats] = useState(false);

  // coachingProgramme Sum
  const coachingProgramme = user?.coached_minutes_overall?.map((data) => {
    let seasonObj = user?.coached_minutes_this_season?.find((item) => {
      return data?.title.trim() === item?.title;
    });

    let coachingProgrammeData = {
      title: data?.title,
      seasonToDate: seasonObj?.value
        ? seasonObj?.value
        : seasonObj?.defaultValue,
      career: data?.value ? data?.value : data?.defaultValue,
    };
    return coachingProgrammeData;
  });

  let coachingProgrammeSeasonTotal = 0;
  let coachingProgrammeOverAllTotal = 0;
  coachingProgramme?.map((data) => {
    coachingProgrammeSeasonTotal += Number(data.seasonToDate);
    coachingProgrammeOverAllTotal += Number(data.career);
    return data;
  });

  // gamesProgramme Sum
  const gamesProgramme_ = user?.game_time_overall?.map((data) => {
    let seasonObj = user?.game_time_season?.find((item) => {
      return data.title.trim() === item?.title;
    });

    let gamesProgrammeData = {
      title: data?.title,
      seasonToDate: seasonObj?.value
        ? seasonObj?.value
        : seasonObj?.defaultValue,
      career: data?.value ? data?.value : data?.defaultValue,
    };
    return gamesProgrammeData;
  });

  let gamesProgramme_SeasonTotal = 0;
  let gamesProgramme_OverAllTotal = 0;
  gamesProgramme_?.map((data) => {
    gamesProgramme_SeasonTotal += Number(data.seasonToDate);
    gamesProgramme_OverAllTotal += Number(data.career);
    return data;
  });

  let appearances_seasonTotal =
    +user?.goals_this_season + +user?.assists_this_season;

  let appearances_overAllTotal = +user?.goals_overall + +user?.assists_overall;

  useEffect(() => {
    const checkStatsData = () => {
      const { appearances, coachedMinutes, gamesProgramme_Minutes } = {
        appearances: {
          season: appearances_seasonTotal,
          overAll: appearances_overAllTotal,
        },
        coachedMinutes: {
          season: coachingProgrammeSeasonTotal,
          overAll: coachingProgrammeOverAllTotal,
        },
        gamesProgramme_Minutes: {
          season: gamesProgramme_SeasonTotal,
          overAll: gamesProgramme_OverAllTotal,
        },
      };

      const hasNonZeroValue = Object.values(appearances)
        .concat(Object.values(coachedMinutes))
        .concat(Object.values(gamesProgramme_Minutes))
        .every((value) => value > 0);

      setShowStats(hasNonZeroValue);
    };

    checkStatsData();
  }, [user]);

  const skills = [
    {
      type: "Physical",
      categories: [
        {
          htmlId: "speed-header",
          category: "Speed",
          sub_skills: user?.speed,
        },
        {
          htmlId: "agility-header",
          category: "Agility",
          sub_skills: user?.agility,
        },
      ],
    },
  ];

  const filteredSkills = skills
    .map((skill) => ({
      ...skill,
      categories: skill?.categories.filter(
        (category) =>
          category?.sub_skills &&
          Array.isArray(category?.sub_skills) &&
          category?.sub_skills?.length > 0
      ),
    }))
    ?.filter((skill) => skill?.categories?.length > 0);

  useEffect(() => {
    if (user) {
      containerWidth();
      hamburgerMenu();
    }
  }, [user]);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const yOffset = -50; // Adjust the offset as needed
      const y =
        section.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (parsed?.type) {
      const decryptedUrl = parsed?.type
        .replaceAll("xMl3JkxMl3Jk", "+")
        .replaceAll("Por21LdPor21Ld", "/")
        .replaceAll("Ml32Ml32", "=");

      const decryptJson = CryptoJS.AES.decrypt(
        decryptedUrl,
        "routes-control-centre"
      ).toString(CryptoJS.enc.Utf8);

      setDecryptType(decryptJson);
    }
  }, [parsed?.type]);

  useEffect(() => {
    if (validExpiryDate && validExpiryDate?.valid === true && !parsed?.type) {
      dispatch(loaderAction.startLoader());
      dispatch(userAction.getUser(params.userId, "interactive"));
    }
    // eslint-disable-next-line
  }, [dispatch, validExpiryDate]);

  useEffect(() => {
    dispatch(loaderAction.startLoader());
    if (!parsed?.type) {
      dispatch(userAction.checkIsPassword(params.userId));
    }
    // eslint-disable-next-line
  }, [dispatch, decryptType]);

  useEffect(() => {
    if (parsed?.type && decryptType === "admin") {
      dispatch(loaderAction.startLoader());
      dispatch(userAction.getUser(params.userId, "interactive"));
    } else {
      if (isPassword === false) {
        dispatch(loaderAction.startLoader());
        dispatch(userAction.getValidateExpiryDate(params.userId));
      }
    }
    // eslint-disable-next-line
  }, [dispatch, decryptType, isPassword]);

  const getExpiryDatePopup = () => {
    alert("Your cv has been expired!");
  };

  // useEffect(() => {
  //   if (validExpiryDate && validExpiryDate?.valid === true) {
  //     dispatch(getUserAction.getUser("be590bb3-8f8a-e86a-a700-88f49d8b63c8"));
  //   }
  // }, [dispatch, validExpiryDate]);

  // const getExpiryDatePopup = () => {
  //   alert("Your cv has been expired");
  // };
  // useEffect(() => {
  //   dispatch(
  //     getValidExpiryDateAction.getValidateExpiryDate(
  //       "be590bb3-8f8a-e86a-a700-88f49d8b63c8"
  //     )
  //   );
  // }, [dispatch])

  const tabDataFilter = (data, name, key) => {
    // console.log(user);
    const filteredArray = data?.filter((item) => {
      return item.hasOwnProperty("name") && item.name !== "";
    });

    // Check if the "Interests" item exists in the filteredArray
    const interestsIndex = filteredArray?.findIndex(
      (item) => item.name === name
    );
    // If "Interests" exists in the array and user interests length is greater than 0, show it
    if (interestsIndex !== -1 && user[key]?.length > 0) {
      return filteredArray;
    } else {
      // Otherwise, remove it from the filteredArray
      if (interestsIndex !== -1) {
        filteredArray.splice(interestsIndex, 1);
      }
      return filteredArray;
    }
  };

  const cvOverviewData = (transferableSkills) => {
    const filteredArray = transferableSkills?.filter((item) => {
      return item.hasOwnProperty("name") && item.name !== "";
    });
    const interestsIndex = filteredArray?.findIndex(
      (item) => item.name === "References"
    );
    if (
      interestsIndex !== -1 &&
      Object.keys(user.cvOverview).length <= 0 &&
      user?.references?.length <= 0
    ) {
      if (interestsIndex !== -1) {
        filteredArray.splice(interestsIndex, 1);
      }
      return filteredArray;
    } else {
      // Otherwise, remove it from the filteredArray

      return filteredArray;
    }
  };

  // useEffect(() => {
  //   if (user) {
  //     let tabsData_ = tabsData;
  //     if (!user?.bioVideo) {
  //       tabsData_ = tabsData?.filter((item) => {
  //         return item.key !== "bio-video";
  //       });
  //     }
  //     const interests_ = tabDataFilter(tabsData_, "Interests", "interests");
  //     const topLevelVideo = tabDataFilter(
  //       interests_,
  //       "Video Gallery",
  //       "topLevelVideos"
  //     );
  //     const transferableSkills = tabDataFilter(
  //       topLevelVideo,
  //       "Transferable Skills",
  //       "transferableSkills"
  //     );
  //     const previous_clubs = tabDataFilter(
  //       transferableSkills,
  //       "Previous Clubs",
  //       "previous_clubs"
  //     );
  //     const agent = tabDataFilter(previous_clubs, "Agent", "agent");
  //     var reference = cvOverviewData(agent);
  //   }

  //   if (user?.organization === "football") {
  //     if (user?.footballAttribute === false) {
  //       const filteredTabsData = reference?.filter(
  //         (data) =>
  //           data.name !== "Football Stats" &&
  //           data.name !== "Physical Markers" &&
  //           data.name !== "Previous Clubs"
  //       );

  //       setInteractiveCvTabdata(filteredTabsData);
  //     } else {
  //       reference?.map((data) => {
  //         data.name =
  //           data.key === "football-stats"
  //             ? `${
  //                 user?.organization[0]?.toUpperCase() +
  //                 user?.organization?.slice(1)
  //               } Stats`
  //             : data?.name;
  //         return data;
  //       });
  //       setInteractiveCvTabdata(reference);
  //     }
  //   } else {
  //     const tabsData_ = reference?.filter(
  //       (data) =>
  //         data.name !== "Football Stats" &&
  //         data.name !== "Physical Markers" &&
  //         data.name !== "Highlights Gallery" &&
  //         data.name !== "Previous Clubs" &&
  //         data.name !== "Agent"
  //     );
  //     setInteractiveCvTabdata(tabsData_);
  //   }
  //   // eslint-disable-next-line
  // }, [user]);

  useEffect(() => {
    if (user) {
      let tabsData_ = tabsData;
      if (!user?.bioVideo) {
        tabsData_ = tabsData?.filter((item) => {
          return item.key !== "bio-video";
        });
      }
      const interests_ = tabDataFilter(tabsData_, "Interests", "interests");
      const topLevelVideo = tabDataFilter(
        interests_,
        "Video Gallery",
        "topLevelVideos"
      );
      const transferableSkills = tabDataFilter(
        topLevelVideo,
        "Transferable Skills",
        "transferableSkills"
      );
      const previous_clubs = tabDataFilter(
        transferableSkills,
        "Previous Clubs",
        "previous_clubs"
      );
      const agent = tabDataFilter(previous_clubs, "Agent", "agent");

      const footballBio = tabDataFilter(agent, "Football Bio", "football_bio");
      var reference = cvOverviewData(footballBio);

      if (user?.organization === "football") {
        if (user?.footballAttribute === false) {
          const filteredTabsData = reference?.filter(
            (data) =>
              data.name !== "Football Stats" &&
              data.name !== "Physical Markers" &&
              data.name !== "Previous Clubs"
          );

          setInteractiveCvTabdata(filteredTabsData);
        } else {
          const updatedReference = reference?.map((data) => {
            if (data.key === "football-stats") {
              return {
                ...data,
                name: `${
                  user?.organization[0]?.toUpperCase() +
                  user?.organization?.slice(1)
                } Stats`,
                show: showStats,
              };
            }
            if (data.name === "Physical Markers") {
              return {
                ...data,
                show: filteredSkills.length > 0,
              };
            }
            return data;
          });
          setInteractiveCvTabdata(
            updatedReference.filter((item) => item.show !== false)
          );
        }
      } else {
        const tabsData_ = reference?.filter(
          (data) =>
            data.name !== "Football Stats" &&
            data.name !== "Physical Markers" &&
            data.name !== "Highlights Gallery" &&
            data.name !== "Previous Clubs" &&
            data.name !== "Agent"
        );
        setInteractiveCvTabdata(tabsData_);
      }
    }
  }, [user, showStats]);

  // useEffect(() => {
  //   // Filter the array to keep only the items with valid "name" property
  //   const filteredArray = interactiveCvTabdata.filter((item) => {
  //     return item.hasOwnProperty("name") && item.name !== "";
  //   });

  //   // Check if the "Interests" item exists in the filteredArray
  //   const interestsIndex = filteredArray.findIndex(
  //     (item) => item.name === "Interests"
  //   );

  //   // If "Interests" exists in the array and user interests length is greater than 0, show it
  //   if (interestsIndex !== -1 && user?.interests?.length > 0) {
  //     console.log(
  //       "Interests exists, show the name:",
  //       filteredArray[interestsIndex].name
  //     );
  //     // setInteractiveCvTabdata(filteredArray[interestsIndex]);
  //   } else {
  //     console.log("in else");
  //     // Otherwise, remove it from the filteredArray
  //     if (interestsIndex !== -1) {
  //       filteredArray.splice(interestsIndex, 1);
  //     }
  //     console.log(
  //       "Interests does not exist or user interests is empty:",
  //       filteredArray
  //     );
  //   }

  //   if (user?.organization === "football") {
  //     if (user?.footballAttribute === false) {
  //       const filteredTabsData = tabsData?.filter(
  //         (data) =>
  //           data.name !== "Football Stats" && data.name !== "My Physical Markers"
  //       );

  //       setInteractiveCvTabdata(filteredTabsData);
  //     } else {
  //       const updatedTabsData = tabsData?.map((data) => {
  //         return {
  //           ...data,
  //           name:
  //             data.key === "football-stats"
  //               ? `${user?.organization}-stats`
  //               : data.name,
  //         };
  //       });
  //       setInteractiveCvTabdata(updatedTabsData);
  //     }
  //   } else {
  //     console.log("else in ");
  //     const filteredTabsData = tabsData?.filter(
  //       (data) =>
  //         data.name !== "Football Stats" &&
  //         data.name !== "Physical Markers" &&
  //         data.name !== "Highlights Gallery" &&
  //         data.name !== "Previous Clubs"
  //     );
  //     setInteractiveCvTabdata(filteredTabsData);
  //   }
  // }, [user, tabsData]);

  // useEffect(() => {
  //   document.title = `${user?.name ? user?.name : ""} CV | Routes`;
  // }, [user]);
  // console.log("interactiveCvTabdata", interactiveCvTabdata);
  // console.log("tab", tab);

  return (
    <>
      <>
        {networkProgressDialog && <Loader />}

        {isPassword === false ? (
          !user &&
          validExpiryDate &&
          validExpiryDate?.valid === false &&
          !networkProgressDialog ? (
            <>{getExpiryDatePopup()}</>
          ) : (
            ((!networkProgressDialog &&
              validExpiryDate &&
              validExpiryDate?.valid === true) ||
              (!networkProgressDialog &&
                parsed?.type &&
                decryptType === "admin")) && (
              <div id="page">
                <div
                  id="burgerMenu"
                  className="d-flex position-fixed w-100 justify-content-end"
                >
                  <button
                    className={`hamburger hamburger--collapse`}
                    type="button"
                  >
                    <span className="hamburger-box">
                      <span className="hamburger-inner"></span>
                    </span>
                  </button>
                </div>
                <div
                  id="nav-menu"
                  className="position-fixed top-0 start-0 w-100 h-100 d-flex flex-column"
                >
                  <div id="menu-outer" className="col">
                    {/* <Image id={"menu-logo"} className="w-100" src={menu_logo} /> */}
                    <Image
                      id={"menu-image"}
                      className="w-100"
                      src={
                        user?.profile_image
                          ? user?.profile_image
                          : "https://routeresourcegroup.blob.core.windows.net/image/user-avatar-placeholder.png"
                      }
                    />

                    <div
                      id="inner-nav"
                      className="col d-flex flex-column align-items-start justify-content-center inner-nav gap-50"
                    >
                      {interactiveCvTabdata?.map((tabs, i) => (
                        <>
                          <a
                            key={tabs?.key}
                            className="nav-item cursor-pointer"
                            onClick={(event) => {
                              event.preventDefault();
                              // console.log("Setting tab:", tabs?.key);
                              setTab(tabs?.key);
                              scrollToSection(tabs?.path);
                            }}
                            href={`#${tabs?.path}`}
                          >
                            <div className="d-flex flex-row nav-item-outer align-items-center justify-content-center">
                              <div
                                className={`active-line ${
                                  tab === tabs?.key ? "active" : ""
                                } `}
                              ></div>

                              <p
                                className={`nav-item-text ${
                                  tab === tabs?.key ? "active" : ""
                                }`}
                              >
                                {tabs?.name}
                              </p>
                            </div>
                          </a>
                        </>
                      ))}
                    </div>
                  </div>
                </div>
                <InteractiveCv />
              </div>
            )
          )
        ) : isPassword === true ? (
          <PasswordForm />
        ) : (
          ""
        )}
      </>
    </>
  );
};

export default Interactive;
