import React from "react";

const Card = ({ interestsCardData }) => {
  return (
    <div className="card">
      <div className="card-body d-flex flex-column p-0">
        <h5 className="card-title font-weight-bold green">
          {interestsCardData.title}
        </h5>
        <p className="card-text">{interestsCardData.description}</p>
      </div>
    </div>
  );
};

export default Card;
