export const CvTabKey = {
  ABOUT_ME: "aboutMe",
  EDUCATION: "education",
  BIO_VIDEO: "bio-video",
  TOP_LEVEL_VIDEO: "video-gallery",
  INTERESTS: "interests",
  PERSONAL_DEVELOPMENT: "personal-development",
  HIGHLIGHTS_GALLERY: "highlights-gallery",
  PREVIOUS_CLUBS: "previous-clubs",
  FOOTBALL_STATS: "football-stats",
  FOUR_CORNER_MODEL: "four-corner-model",
  TRANSFERABLE_VALUES: "transferable-values",
  REFERENCES: "references",
  AGENT: "agent",
  MISC: "miscAttributes",
  FOOTBALL_BIO: "football-bio",
};

export const tabsData = [
  {
    name: "About Me",
    key: CvTabKey.ABOUT_ME,
    path: CvTabKey.ABOUT_ME,
    // component: <AboutMe />,
  },
  {
    name: "Bio Video",
    key: CvTabKey.BIO_VIDEO,
    path: CvTabKey.BIO_VIDEO,
    // component: <>Bio Video</>,
  },
  {
    name: "Education",
    key: CvTabKey.EDUCATION,
    path: CvTabKey.EDUCATION,
    // component: <Education />,
  },
  {
    name: "Video Gallery",
    key: CvTabKey.TOP_LEVEL_VIDEO,
    path: CvTabKey.TOP_LEVEL_VIDEO,
    // component: <>Bio Video</>,
  },
  {
    name: "Interests",
    key: CvTabKey.INTERESTS,
    path: CvTabKey.INTERESTS,
    // component: <>Interests</>,
  },
  {
    name: "Personal Development",
    key: CvTabKey.PERSONAL_DEVELOPMENT,
    path: CvTabKey.PERSONAL_DEVELOPMENT,
    // component: <>Personal Development</>,
  },
  {
    name: "Football Bio",
    key: CvTabKey.FOOTBALL_BIO,
    path: CvTabKey.FOOTBALL_BIO,
    // component: <>Football Bio</>,
  },
  // {
  //   name: "Highlights Gallery",
  //   key: CvTabKey.HIGHLIGHTS_GALLERY,
  //   path: CvTabKey.HIGHLIGHTS_GALLERY,
  //   // component: <>Highlights Gallery</>,
  // },
  {
    name: "Previous Clubs",
    key: CvTabKey.PREVIOUS_CLUBS,
    path: CvTabKey.PREVIOUS_CLUBS,
    // component: <>Previous Clubs</>,
  },
  {
    name: "Football Stats",
    key: CvTabKey.FOOTBALL_STATS,
    path: CvTabKey.FOOTBALL_STATS,
    // component: <>Football Stats</>,
  },
  {
    name: "Physical Markers",
    key: CvTabKey.FOUR_CORNER_MODEL,
    path: CvTabKey.FOUR_CORNER_MODEL,
    // component: <>My Physical Markers</>,
  },
  {
    name: "Transferable Skills",
    key: CvTabKey.TRANSFERABLE_VALUES,
    path: CvTabKey.TRANSFERABLE_VALUES,
    // component: <>Transferable Values</>,
  },
  {
    name: "References",
    key: CvTabKey.REFERENCES,
    path: CvTabKey.REFERENCES,
    // component: <>References</>,
  },
  {
    name: "Agent",
    key: CvTabKey.AGENT,
    path: CvTabKey.AGENT,
    // component: <>Agent</>,
  },
  // {
  //   name: "Misc",
  //   key: CvTabKey.MISC,
  //   path: CvTabKey.MISC,
  //   // component: <>MiscAttributes</>,
  // },
];
