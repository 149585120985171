import React from "react";
import Heading from "components/pages/Sports/TraditionalCv/components/Heading/Heading";
import { useSelector } from "react-redux";

const References = () => {
  let { user } = useSelector((state) => state.user);
  return (
    <>
      <Heading name="References" />
      <div className="colGrid3 marginBottom">
        {user?.references?.length > 0 &&
          user?.references?.map((data) => (
            <div>
              <h6 className="green bold marginBottomSmall">
                {data?.name ? data?.name : ""}
              </h6>
              <p>
                {data?.position ? data?.position : ""}
                <br />
                {data?.clubName ? data?.clubName : ""}
                <br />
                {data?.contact ? data?.contact : ""}
                <br />
                {data?.email ? data?.email : ""}
              </p>
            </div>
          ))}
      </div>
    </>
  );
};

export default References;
