import React from "react";

const Image = (props) => {
  const {
    id = "",
    src = "",
    alt = "",
    className = "",
    style,
    onClick,
    title,
    aspect,
    minHeight,
    minWidth,
    maxWidth,
    width,
    height,
  } = props;
  return (
    <img
      id={id}
      src={src}
      alt={alt}
      className={className}
      style={style}
      onClick={onClick}
      title={title}
      aspect={aspect}
      minheight={minHeight}
      minwidth={minWidth}
      maxwidth={maxWidth}
      maxheight={100}
      width={width}
      height={height}
    />
  );
};

export default Image;
