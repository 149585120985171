import React from "react";
import { useSelector } from "react-redux";
import SkillModel from "components/FourCornerModel/SkillModel/SkillModel";
import Card from "components/Interests/Card";
import MiscAttributes from "components/MiscAttributes";

const FourCornerModel = () => {
  let { user } = useSelector((state) => state.user);

  const skills = [
    // {
    //   type: "Technical",
    //   categories: [
    //     {
    //       htmlId: "passing-header",
    //       category: "Passing",

    //       sub_skills: [
    //         { name: "One touch", level: 4 },
    //         { name: "Short", level: 2 },
    //         { name: "Long", level: 2 },
    //         { name: "Under Pressure", level: 2 },
    //         { name: "On the ground", level: 1 },
    //         { name: "In the air", level: 4 },
    //       ],
    //     },
    //     {
    //       htmlId: "turning-header",
    //       category: "Turning",
    //       sub_skills: [
    //         { name: "With the ball", level: 4 },
    //         { name: "Without the ball", level: 2 },
    //         { name: "Under Pressure", level: 2 },
    //         // { name: "", level: "" },
    //         // { name: "", level: "" },
    //         // { name: "", level: "" },
    //       ],
    //     },
    //   ],
    // },
    {
      type: "Physical",
      categories: [
        {
          htmlId: "speed-header",
          category: "Speed",
          sub_skills: user?.speed,
        },
        {
          htmlId: "agility-header",
          category: "Agility",
          sub_skills: user?.agility,
        },
      ],
    },
  ];

  const filteredSkills = skills
    .map((skill) => ({
      ...skill,
      categories: skill.categories.filter(
        (category) =>
          category.sub_skills &&
          Array.isArray(category.sub_skills) &&
          category.sub_skills.length > 0
      ),
    }))
    .filter((skill) => skill.categories.length > 0);

  if (filteredSkills.length === 0) {
    return null;
  }

  return (
    <div
      id="four-corner-model"
      className="flex-fill d-flex flex-row gut-100 section"
    >
      <div className="col d-flex flex-column align-items-start justify-content-center content-box">
        <h2 id="previous-clubs-title" className="text-right">
          My <span className="font-weight-bold">Physical Markers</span>
        </h2>
        <div id="technical" className="d-flex flex-column gap-50 w-100">
          {skills?.map((data) => (
            <>
              <h5 className="green font-weight-bold">{data?.type}</h5>
              {data?.categories?.map((categories) => (
                <SkillModel
                  skill_categories={categories}
                  skillType={data?.type}
                />
              ))}
            </>
          ))}
        </div>
        <div className="row w-100 d-flex gap-50 flex-wrap interest-row">
          {user?.physical_attributes?.map((data) => (
            <div className="col-md-4 p-0 d-flex stretch flex-unset fourCornModel_card">
              <Card interestsCardData={data} />
            </div>
          ))}
        </div>

        <MiscAttributes Category="physical markers" />
      </div>
    </div>
  );
};

export default FourCornerModel;
