import { combineReducers } from "redux";
import loaderReducer from "./Loader/reducer";
import userReducer from "./User/reducer";

const combinedReducer = combineReducers({
  user: userReducer,
  loader: loaderReducer,
});

const rootReducer = (state, action) => {
  // if (action.type === "USER_LOGOUT_REQUEST") {
  //   state = undefined;
  // }
  return combinedReducer(state, action);
};

export default rootReducer;
