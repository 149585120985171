import React from "react";
import { useSelector } from "react-redux";
import { stats_expandInfo_title } from "components/FootballStats/constants";
import DataTable from "components/FootballStats/ExpandInfo/DataTable/DataTable";

const AppearancesExpandInfo = () => {
  let { user } = useSelector((state) => state.user);

  const gamesProgramme_ = user?.game_time_overall?.map((data) => {
    let seasonObj = user?.game_time_season?.find((item) => {
      return data.title.trim() === item?.title;
    });

    let gamesProgrammeData = {
      title: data?.title,
      seasonToDate: seasonObj?.value
        ? seasonObj?.value
        : seasonObj?.defaultValue,
      career: data?.value ? data?.value : data?.defaultValue,
    };
    return gamesProgrammeData;
  });

  return (
    <div
      id="playing-stats-expanded"
      className="col-auto d-flex justify-content-center stat-box flex-column pb-25"
    >
      <div className="row item align-items-end p-0 pt-25 pb-25 gap-50 flex-nowrap w-100">
        <div className="col-8 p-0 flex-unset"></div>
        {stats_expandInfo_title?.map((data) => (
          <div className="col-2 d-flex justify-content-end p-0 flex-unset">
            <p className="green font-weight-bold">{data?.name}</p>
          </div>
        ))}
      </div>
      {gamesProgramme_?.map((data) => (
        <DataTable gamesProgramme={data} />
      ))}
      {/* <!-- Additional items here --> */}
      <div className="col-12 expand-collapse-seperator d-flex flex-unset pt-25"></div>
    </div>
  );
};

export default AppearancesExpandInfo;
