import React from "react";
import Sidebar from "components/pages/Sports/TraditionalCv/components/Sidebar";
import Interests from "components/pages/Sports/TraditionalCv/components/Interests";
import WorkExperience from "components/pages/Sports/TraditionalCv/components/WorkExperience";
import Education from "components/pages/Sports/TraditionalCv/components/Education";
import Achievements from "components/pages/Sports/TraditionalCv/components/Achievements";
import References from "components/pages/Sports/TraditionalCv/components/References";
import Footer from "components/pages/Sports/TraditionalCv/components/Footer";
import PreviousClubs from "components/pages/Sports/TraditionalCv/components/PreviousClubs";
import FootballStats from "components/pages/Sports/TraditionalCv/components/FootballStats";
import TransferableSkills from "components/pages/Sports/TraditionalCv/components/TransferableSkills";
import FourCornerModel from "components/pages/Sports/TraditionalCv/components/FourCornerModel";
import { useSelector } from "react-redux";
import { INSTITUTE_ID_ACADEMIC_SPORT } from "components/pages/constants";

const TraditionalCv = () => {
  let { user } = useSelector((state) => state.user);
  return (
    <>
      <div className="page">
        <div className="mainGrid">
          <Sidebar />
          <div>
            <Interests />
            <WorkExperience />
            <Education />
            <Achievements />
            <References />
          </div>
        </div>
        <Footer page="1" />
      </div>
      <div className="pageBreak"></div>
      <div className="page">
        <div className="mainGrid">
          <Sidebar />
          <div className="rightCol">
            {user?.footballAttribute === true && (
              <>
                {user?.club?.instituteId === INSTITUTE_ID_ACADEMIC_SPORT && (
                  <PreviousClubs />
                )}
                {user?.organization === "football" && <FootballStats />}
              </>
            )}
            <TransferableSkills />
            {user?.footballAttribute === true && (
              <>{user?.organization === "football" && <FourCornerModel />}</>
            )}
          </div>
        </div>
        <Footer page="2" />
      </div>
    </>
  );
};

export default TraditionalCv;
