import React from "react";
import { useSelector } from "react-redux";

const FootballBio = () => {
  let { user } = useSelector((state) => state.user);
  return (
    <>
      <div id="football-bio" className="section">
        <h2 id="personal-development-title ">
          My <span className="font-weight-bold">Football Bio</span>
        </h2>
      </div>
      <div>
        {user?.organization === "football" && (
          <div
            id="tutor-comment"
            className="d-flex justify-content-center align-items-center section"
          >
            {user?.footballAttribute === true && (
              <div className="col-6 col comment-main">
                <h5 className="text-center light-grey ">
                  {user?.football_bio ? user?.football_bio : ""}
                </h5>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default FootballBio;
