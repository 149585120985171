// import "assets/css/main.css";
import Interactive from "pages/assets/Sports/Interactive";
import Traditional from "pages/assets/Sports/Traditional";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import { useEffect } from "react";
import { useSelector } from "react-redux";

function App() {
  let { user } = useSelector((state) => state.user);

  useEffect(() => {
    if (user && user?.brandTheme) {
      const root = document.documentElement;
      root.style.setProperty(
        "--primary-color",
        user?.brandTheme?.themeColors?.primary || "#006b00"
      );
      root.style.setProperty(
        "--secondary-color",
        user?.brandTheme?.themeColors?.secondary || "#006b00"
      );
      root.style.setProperty(
        "--hover-color",
        user?.brandTheme?.themeColors?.hover || "#006b00"
      );
    }
    if (user?.brandTheme && user?.brandTheme?.logos?.favicon) {
      const favicon = document.querySelector('link[rel="icon"]');
      if (favicon) {
        favicon.href = user?.brandTheme?.logos?.favicon;
      } else {
        const newFavicon = document.createElement("link");
        newFavicon.rel = "icon";
        newFavicon.href = user?.brandTheme?.logos?.favicon;
        document.head.appendChild(newFavicon);
      }
    }

    if (user?.brandTheme?.brandName) {
      document.title = `${user?.name ? user?.name : ""} CV | ${
        user?.brandTheme?.brandName ? user?.brandTheme?.brandName : "Routes"
      }`;
    } else {
      document.title = `${user?.name ? user?.name : ""} CV | Routes`;
    }
  }, [user]);

  // const params = useParams();
  // let { user, validExpiryDate } = useSelector((state) => state.user);
  // useEffect(() => {
  //   containerWidth();
  // }, []);

  // useEffect(() => {
  //   if (validExpiryDate && validExpiryDate?.valid === true) {
  //     dispatch(loaderAction.startLoader());
  //     dispatch(getUserAction.getUser(params.userId));
  //   }
  // }, [dispatch, validExpiryDate]);

  // useEffect(() => {
  //   dispatch(loaderAction.startLoader());
  //   dispatch(getValidExpiryDateAction.getValidateExpiryDate(params.userId));
  // }, [dispatch]);
  // const getExpiryDatePopup = () => {
  //   alert("Your cv has been expired");
  // };
  return (
    <>
      <Routes>
        <Route path="/cv/:userId" element={<Interactive />} />
        <Route exact path="/cv/traditional/:userId" element={<Traditional />} />
      </Routes>
    </>
  );
}
export default App;
