import React from "react";
import Heading from "components/pages/Sports/TraditionalCv/components/Heading/Heading";
import GCSE from "components/pages/Sports/TraditionalCv/components/Education/GCSE/GCSE";
import Course from "components/pages/Sports/TraditionalCv/components/Education/Course/Course";
import { useSelector } from "react-redux";

const Education = () => {
  let { user } = useSelector((state) => state.user);

  return (
    <>
      <Heading name="Education" />
      <div className="colGrid3 marginBottom">
        <GCSE />

        <div>
          <h6 className="green bold marginBottomSmall">
            OTHER ACADEMIC QUALIFICATIONS
          </h6>
          {user?.Other_academic_qualification?.length > 0 &&
            user?.Other_academic_qualification?.map((data) => {
              return (
                <p className="flexRow SB">
                  {data?.title ? data?.title : ""}
                  <span className="green">
                    {data?.value ? data?.value : ""}
                  </span>
                </p>
              );
            })}
        </div>

        <Course />
      </div>
    </>
  );
};

export default Education;
