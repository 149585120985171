import { moduleImg } from "components/Education/constants";
import Image from "components/Image";
import Attachment from "components/Education/ExpandInfo/Attachment/Attachment";
import ModuleAttachment from "components/Education/ExpandInfo/ModuleAttachment/ModuleAttachment";
import placeHolderImgCourse from "assets/images/placeHolderImgCourse.png";
import Icons from "assets/icons/Icons";
import { useSelector } from "react-redux";

const UnitAttachment = ({ moduleAttachmentData, index, indexOfCourse }) => {
  const { user } = useSelector((state) => state.user);
  return (
    <div
      // id="course-1-unit-1-attachments"
      id={`course-${indexOfCourse}-unit-${index + 1}-attachments`}
      className="w-100 education-attachment d-flex flex-row gap-10 flex-nowrap justify-content-start hidden">
      <div className="attachment-inner d-flex flex-column gap-25 w-100 attachment-list">
        {moduleAttachmentData?.units?.length > 0 && (
          <div
            // id="course-1-unit-1-modules"
            id={`course-${indexOfCourse}-unit-${index + 1}-modules`}
            className="align-items-center d-flex flex-row gap-25 w-100 flex-nowrap module-row">
            {moduleAttachmentData?.units?.map((data, i) => (
              <div
                // id="course-1-unit-1-module-1"
                id={`course-${indexOfCourse}-unit-${index + 1}-module-${i + 1}`}
                className="module d-flex flex-row align-items-center justify-content-center gap-10 p-0 cursor-pointer">
                {data?.icon ? (
                  <Image
                    // id="course-1-unit-1-module-1-icon"
                    id={`course-${indexOfCourse}-unit-${
                      index + 1
                    }-module-1-icon`}
                    className="module-icon"
                    src={data?.icon ? data?.icon : moduleImg[1].image}
                    height={"30px"}
                    // src={i % 2 === 1 ? moduleImg[1].image : moduleImg[0].image}
                  />
                ) : (
                  <Icons
                    name="module-icon-rectangle"
                    stroke={user?.brandTheme?.themeColors?.primary}
                  />
                )}

                <p
                  // id="course-1-unit-1-module-1-title"
                  id={`course-${indexOfCourse}-unit-${i + 1}-module-1-title`}
                  className="module-title">
                  {data?.name}
                </p>
              </div>
            ))}
          </div>
        )}
        <div
          // id="course-1-unit-1-attachments-list"
          id={`course-${indexOfCourse}-unit-${index + 1}-attachments-list`}
          className="w-100 education-attachment-list d-flex flex-column gap-25 flex-nowrap justify-content-start">
          <div className="grade-attachment align-items-center d-flex flex-row gap-50 w-100 flex-nowrap">
            <div className="col-md-6 flex-unset flex-row d-flex p-0 gap-10">
              <div className="col-md-3 flex-unset flex-row d-flex unit p-0 align-items-center unit-icon-div">
                <Image
                  className="unit-icon predicted-icon"
                  src={
                    moduleAttachmentData?.icon
                      ? moduleAttachmentData?.icon
                      : placeHolderImgCourse
                  }
                />
              </div>
              <div className="col-md-9 d-flex flex-column align-items-start p-0 justify-content-center">
                <p className="course-title">
                  {moduleAttachmentData?.name ? moduleAttachmentData?.name : ""}
                </p>
              </div>
            </div>
            <div className="col-md-6 flex-unset flex-row d-flex p-0 align-items-center">
              <div className="col-md-12 d-flex flex-row align-items-start p-0 justify-content-center gap-50">
                <p className="grade-text achieved">
                  {/* {moduleAttachmentData?.gradeTitle} */}
                  {moduleAttachmentData?.achivedGrade === null
                    ? moduleAttachmentData?.predictedGrade === null
                      ? ""
                      : "Predicted Grade"
                    : "Achieved Grade"}
                </p>
                <p className="grade-result achieved font-weight-bold">
                  {/* {moduleAttachmentData?.grade} */}

                  {moduleAttachmentData?.achivedGrade === null
                    ? moduleAttachmentData?.predictedGrade === null
                      ? ""
                      : moduleAttachmentData?.predictedGrade
                    : moduleAttachmentData?.achivedGrade}
                </p>
              </div>
            </div>
          </div>
          <div className="attachment-separator"></div>

          {moduleAttachmentData?.tutorComments?.length > 0 &&
            moduleAttachmentData?.tutorComments?.map((data, i) => (
              <>
                <Attachment attachmentsData={data} />
                {i < moduleAttachmentData?.tutorComments?.length - 1 && (
                  <div className="attachment-separator"></div>
                )}
              </>
            ))}
          {moduleAttachmentData?.tutorComments?.length > 0 && (
            <div className="attachment-separator"></div>
          )}

          {moduleAttachmentData?.attachments
            ?.filter((item) => item?.type === "text")
            .map((data, i) => (
              <>
                <Attachment attachmentsData={data} />
                {i <
                  moduleAttachmentData?.attachments?.filter(
                    (item) => item?.type === "text"
                  )?.length && <div className="attachment-separator"></div>}
              </>
            ))}

          {moduleAttachmentData?.attachments
            ?.filter((item) => item?.type !== "text")
            .map((data, i) => (
              <>
                <Attachment attachmentsData={data} />
                {i <
                  moduleAttachmentData?.attachments?.filter(
                    (item) => item?.type !== "text"
                  )?.length -
                    1 && <div className="attachment-separator"></div>}
              </>
            ))}
        </div>

        {moduleAttachmentData?.units?.map((data, i) => (
          <ModuleAttachment
            key={i}
            // moduleAttachmentData={data}
            unitAttachmentData={data}
            index={i}
            indexOfUnit={index}
            indexOfCourse={indexOfCourse}
          />
        ))}
      </div>
    </div>
  );
};

export default UnitAttachment;
