import Image from "components/Image";
import Attachment from "components/Education/ExpandInfo/Attachment/Attachment";
import UnitAttachment from "components/Education/ExpandInfo/UnitAttachment/UnitAttachment";
import placeHolderImgCourse from "assets/images/placeHolderImgCourse.png";

const Attachments = ({ attachmentsData, index }) => {
  return (
    <div
      id={`card${index}-attachments`}
      className="col-md-8 flex-unset d-flex flex-column p-0 attachment-div"
    >
      <div
        id={`course-${index}-attachments`}
        className="w-100 education-attachment d-flex flex-row gap-10 flex-nowrap justify-content-start"
      >
        <div className="attachment-inner d-flex flex-column gap-25 w-100 attachment-list">
          <div className="grade-attachment align-items-start d-flex flex-row gap-50 w-100 flex-nowrap">
            <div className="col-md-6 flex-unset flex-row d-flex p-0 gap-10">
              <div className="col-md-3 flex-unset flex-row d-flex unit p-0 align-items-center unit-icon-div">
                <Image
                  src={
                    attachmentsData?.image
                      ? attachmentsData?.image
                      : placeHolderImgCourse
                  }
                  className={"unit-icon predicted-icon"}
                />
              </div>
              <div className="col-md-9 d-flex flex-column align-items-start p-0 justify-content-center">
                <p className="course-title">
                  {attachmentsData?.course ? attachmentsData?.course : ""}
                </p>
              </div>
            </div>
            <div className="col-md-6 flex-unset flex-row d-flex p-0 align-items-center">
              <div className="col-md-12 d-flex flex-row align-items-start p-0 justify-content-center gap-50">
                <p className="grade-text achieved">
                  {attachmentsData?.module?.gradeTitle
                    ? attachmentsData?.module?.gradeTitle
                    : ""}
                </p>
                <p className="grade-result achieved font-weight-bold">
                  {attachmentsData?.module?.maxGrade
                    ? attachmentsData?.module?.maxGrade
                    : ""}
                  {/* {attachmentsData?.achivedGrade === null
                    ? attachmentsData?.predictedGrade
                      ? attachmentsData?.achivedGrade
                      : attachmentsData?.predictedGrade === null
                    : ""} */}
                </p>
              </div>
            </div>
          </div>
          <div className="attachment-separator"></div>
          {attachmentsData?.tutorComments?.length > 0 &&
            attachmentsData?.tutorComments?.map((data, i) => (
              <>
                <Attachment attachmentsData={data} />
                {i < attachmentsData?.tutorComments?.length - 1 && (
                  <div className="attachment-separator"></div>
                )}
              </>
            ))}
          {attachmentsData?.tutorComments?.length > 0 && (
            <div className="attachment-separator"></div>
          )}

          {attachmentsData?.attachments
            ?.filter((item) => item?.type === "text")
            .map((data, i) => (
              <>
                <Attachment attachmentsData={data} />
                {i <
                  attachmentsData?.attachments?.filter(
                    (item) => item?.type === "text"
                  )?.length && <div className="attachment-separator"></div>}
              </>
            ))}

          {attachmentsData?.attachments?.length > 0 &&
            attachmentsData?.attachments
              ?.filter((item) => item?.type !== "text")
              .map((data, i) => (
                <>
                  <Attachment attachmentsData={data} />
                  {i <
                    attachmentsData?.attachments?.filter(
                      (item) => item?.type !== "text"
                    )?.length -
                      1 && <div className="attachment-separator"></div>}
                </>
              ))}
        </div>
      </div>
      {attachmentsData?.modulesData?.length > 0 &&
        attachmentsData?.modulesData?.map((data, i) => (
          <UnitAttachment
            key={i}
            // unitAttachmentData={data}
            moduleAttachmentData={data}
            index={i}
            indexOfCourse={index}
          />
        ))}
    </div>
  );
};

export default Attachments;
