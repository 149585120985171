import React from "react";
import { useSelector } from "react-redux";

const CoachedMinutesExpandInfo = () => {
  let { user } = useSelector((state) => state.user);

  const coachingProgramme = user?.coached_minutes_overall?.map((data) => {
    let seasonObj = user?.coached_minutes_this_season?.find((item) => {
      return data?.title.trim() === item?.title;
    });

    let coachingProgrammeData = {
      title: data?.title,
      seasonToDate: seasonObj?.value
        ? seasonObj?.value
        : seasonObj?.defaultValue,
      career: data?.value ? data?.value : data?.defaultValue,
    };
    return coachingProgrammeData;
  });

  // const coachingProgramme = [
  //   { seasonToDate: 56, career: 100, title: "Conditioning" },
  //   { seasonToDate: 80, career: 693, title: "Football Based" },
  //   { seasonToDate: 60, career: 672, title: "Injury Prevention" },
  // ];

  return (
    <div
      id="coaching-stats-expanded"
      className="col-auto d-flex justify-content-center stat-box flex-column pb-25"
    >
      <div className="row item align-items-end p-0 pt-25 pb-25 gap-50 flex-nowrap w-100">
        <div className="col-8 p-0 flex-unset"></div>
        <div className="col-4 d-flex justify-content-end p-0 flex-unset">
          <p className="green font-weight-bold">Minutes</p>
        </div>
      </div>
      {coachingProgramme?.map((data) => (
        <div className="row item align-items-end p-0 pt-15 gap-50 flex-nowrap w-100">
          <div className="col-8 item-label p-0 flex-unset">
            <p>{data?.title}</p>
          </div>
          <div className="col-4 item-details p-0 flex-unset">
            <div className="row">
              <div className="col-6 d-flex justify-content-end p-0">
                <div className="column-value">
                  <p className="career-text">{data?.seasonToDate}</p>
                </div>
              </div>
              <div className="col-6 d-flex justify-content-end p-0">
                <div className="column-value">
                  <p className="career-text">{data?.career}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}

      {/* <!-- Additional items here --> */}
      <div className="col-12 expand-collapse-seperator d-flex flex-unset pt-25"></div>
    </div>
  );
};

export default CoachedMinutesExpandInfo;
