import Icons from "assets/icons/Icons";
import React from "react";
import { useSelector } from "react-redux";

const ReferencesCard = ({ tutorDetails, className, index, sectionName }) => {
  let { user } = useSelector((state) => state.user);

  return (
    <div
      // id="reference-1"
      // id={tutorDetails?.innerDivId}
      id={`reference-${index + 1}`}
      className="reference-box d-flex flex-row flex-nowrap align-items-center gap-25">
      <div className={`col-md-9 d-flex flex-column ${className}`}>
        <h5 id="tutor-name" className="green font-weight-bold">
          {tutorDetails?.name}
        </h5>
        <p id="tutor-role" className="">
          {tutorDetails?.position}
        </p>
        <p id="tutor-club" className="">
          {tutorDetails?.clubName}
        </p>
      </div>
      <div className="col-md-3 d-flex justify-content-end gap-25 p-0">
        <div className="contact-icons d-flex flex-row gap-25">
          {sectionName === "agent" && (
            <a
              href={`tel:${tutorDetails?.contact}`}
              className="phone-icon-link contact-link d-flex justify-content-center align-items-center">
              <Icons
                name="phone"
                fill={user?.brandTheme?.themeColors?.primary}
              />
            </a>
          )}
        </div>
        <div className="contact-icons d-flex flex-row gap-25">
          <a
            href={`mailto:${tutorDetails?.email}`}
            className="phone-icon-link contact-link d-flex justify-content-center align-items-center">
            <Icons
              name="e-mail"
              fill={user?.brandTheme?.themeColors?.primary}
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default ReferencesCard;
